import { call, put, select, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { RealtimeGameData } from "@all-good-apps/draft-chess-common";
import { authHelpers, gameDataSelectors, gamesActions } from "@all-good-apps/draft-chess-client-api";

export const DISMISS_SUGGESTION_ACTION = `games/DISMISS_SUGGESTION_ACTION`;
export const dismissSuggestion = (gameId: string, move: string) => ({
  type: DISMISS_SUGGESTION_ACTION,
  payload: {
    gameId,
    move,
  },
});
export type DismissSuggestionAction = ReturnType<typeof dismissSuggestion>;

function* handleDismissSuggestion(action: DismissSuggestionAction) {
  const { gameId, move } = action.payload;
  const gameData: RealtimeGameData = yield select(
    gameDataSelectors.gameDataById,
    gameId
  );

  try {
    const response: AxiosResponse = yield call(
      authHelpers.authenticatedRequest,
      {
        method: "POST",
        url: `game/${gameId}/dismissSuggestion/${move}`,
      }
    );
    const { status, data } = response;

    if (status === 200) {
      yield put(gamesActions.updateGameData(data));
    } else {
      // if there is a problem with the move, revert the optimistic change
      yield put(gamesActions.updateGameData(gameData));
    }
  } catch (e) {
    console.log(e);
    // if there is a problem with the move, revert the optimistic change
    yield put(gamesActions.updateGameData(gameData));
  }
}

export default takeLatest(DISMISS_SUGGESTION_ACTION, handleDismissSuggestion);
