import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import profileSelector from "../../../selectors/profileSelector"
import { Button, TextField } from "@mui/material"
import Stack from "@mui/material/Stack"
import profileActions from "../../../actions/profileActions"

type Props = {
  onClick: () => void | undefined
}

const ChangeDisplayNameForm = (props: Props) => {
  const { onClick } = props
  const dispatch = useDispatch()
  const [displayName, setDisplayName] = useState("")
  const user = useSelector(profileSelector.userInfoPublic)

  const handleUpdateDisplayName = () => {
    dispatch(profileActions.updateDisplayName(displayName))
    if (onClick) {
      onClick()
    }
  }

  return (
    <Stack
      spacing={1}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TextField
        color="primary"
        variant="outlined"
        required
        id="displayName"
        label="Change Display Name"
        name="displayName"
        autoComplete="displayName"
        defaultValue={user.displayName}
        autoFocus
        onChange={(event) => setDisplayName(event.currentTarget.value)}
        size="small"
      />

      <Stack spacing={1} direction={"row"}>
        <Button variant="contained" color="secondary" size="small" onClick={props.onClick}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleUpdateDisplayName}>
          Save
        </Button>
      </Stack>
    </Stack>
  )
}

export default ChangeDisplayNameForm
