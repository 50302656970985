import { Reducer } from "redux"
import { PublicProfile } from "@all-good-apps/draft-chess-common"
import { UpdatePublicUserInfoAction, UPDATE_PUBLIC_USER_INFO_ACTION } from "../actions/profileActions"

export interface PublicUserByIdReducerState {
    userInfoPublic: PublicProfile
}

const initialState: PublicUserByIdReducerState = {
    userInfoPublic: {
        displayName: "",
        friends: [],
        rating: -1,
        uid: "",
    }
}

const publicProfileReducer: Reducer<PublicUserByIdReducerState, UpdatePublicUserInfoAction> = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PUBLIC_USER_INFO_ACTION:
            const { profile } = action.payload
            return {
                ...state,
                userInfoPublic: profile,
            }
        default: return state
    }
}

export default publicProfileReducer
