import { useMemo } from "react"
import { isMobile } from "react-device-detect"
import { useParams } from "react-router-dom"
import TemplatePage from "../../common/components/TemplatePage"
import useWindowDimensions from "../../common/hooks/useWindowDimentions"
import { GameContextProvider } from "@all-good-apps/draft-chess-client-api"
import DraftChessGameBoard from "./DraftChessGameBoard"

type Props = {
  children: React.ReactNode
}

const ScreenDiv = (props: Props) => {
  const { children } = props
  return (
    <div
      style={{
        flex: 1,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  )
}

const GamePage = () => {
  const { gameId } = useParams()

  const { height, width } = useWindowDimensions()
  const boardWidth = useMemo(() => (isMobile ? width - 40 : height / 2 - 20), [width, height, isMobile])

  return (
    <GameContextProvider gameId={gameId as string}>
      <TemplatePage onGamePage>
        <ScreenDiv>
          <DraftChessGameBoard boardWidth={boardWidth} />
        </ScreenDiv>
      </TemplatePage>
    </GameContextProvider>
  )
}

export default GamePage
