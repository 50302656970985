import { ChallengeRequest, RematchRequest, UserRequest } from "@all-good-apps/draft-chess-common"
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore"
import { EventChannel, eventChannel } from "redux-saga"
import { put, race, select, take } from "redux-saga/effects"
import { firestore } from "../../../firebase/admin"
import { authActions, authSelectors } from "@all-good-apps/draft-chess-client-api"
import challengeActions from "../actions/challengeActions"
import offerDrawActions from "../actions/drawRequestActions"
import friendRequestActions from "../actions/friendRequestActions"
import rematchRequestActions from "../actions/rematchRequestActions"

const userRequestsChannel = (uid: string): EventChannel<UserRequest[]> => {
  return eventChannel<UserRequest[]>((emit) => {
    const collectionRef = collection(firestore, "userRequests")

    const now = Date.now()
    // first, get all request that still have the "issued" status
    // there are request we want to show the user
    const initialQuery = query(collectionRef, where("players", "array-contains", uid), where("issuedAt", "<", now))
    getDocs(initialQuery).then((docs) => {
      const requests = docs.docs.map((doc) => doc.data())
      emit(requests as UserRequest[])
    })

    // then, listen for all changes to requests after right now
    const userRequestsQuery = query(collectionRef, where("players", "array-contains", uid), where("issuedAt", ">", now))

    return onSnapshot(userRequestsQuery, (snapshot) => {
      const requests = snapshot.docChanges().map((docChange) => docChange.doc.data())
      emit(requests as UserRequest[])
    })
  })
}

function* listenForUserRequestChanges(uid: string) {
  const userRequestChannel: EventChannel<UserRequest[]> = yield userRequestsChannel(uid)
  while (true) {
    const updatedUserRequests: UserRequest[] = yield take(userRequestChannel)

    const challenges: UserRequest[] = []
    const friendRequests: UserRequest[] = []
    const drawRequests: UserRequest[] = []
    const rematchRequests: UserRequest[] = []

    for (const request of updatedUserRequests) {
      if (request.type === "challengeRequest") {
        challenges.push(request)
      } else if (request.type === "friendRequest") {
        friendRequests.push(request)
      } else if (request.type === "drawRequest") {
        drawRequests.push(request)
      } else if (request.type === "rematchRequest") {
        rematchRequests.push(request)
      }
    }

    if (challenges.length !== 0) {
      yield put(challengeActions.updateChallenges(challenges as ChallengeRequest[]))
    }

    if (friendRequests.length !== 0) {
      yield put(friendRequestActions.updateRequests(friendRequests))
    }

    if (drawRequests.length !== 0) {
      yield put(offerDrawActions.updateDrawRequests(drawRequests))
    }

    if (rematchRequests.length !== 0) {
      yield put(rematchRequestActions.updateRematchRequests(rematchRequests as RematchRequest[]))
    }
  }
}

function* listenForUserRequests() {
  while (true) {
    yield take(authActions.USER_LOGGED_IN)
    const uid: string = yield select(authSelectors.uid)
    yield race([listenForUserRequestChanges(uid), take(authActions.USER_LOGGED_OUT)])
  }
}

export default listenForUserRequests
