import { ButtonBase } from "@mui/material"
import { Move } from "chess.js"
import { memo, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { gamesActions } from "@all-good-apps/draft-chess-client-api"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";
import { playFlipSound, playSound } from "../../sound/sagas/handlePlaySound"
import useSuggestionContext from "../hooks/useSuggestionContext"
import { highlightMove } from "../sagas/handleHighlightMove"
import FlippableSuggestionCard from "./FlippableSuggestionCard"

interface Props {
  suggestion?: Move
  boardWidth: number
}

const SuggestionButton = (props: Props) => {
  const { suggestion, boardWidth } = props
  const { gameId } = gameHooks.useGameContext()
  const { player, highlightedMove } = useSuggestionContext()
  const dispatch = useDispatch()

  const highlighted = !!suggestion && !!highlightMove && suggestion?.san === highlightedMove?.san
  const [isHighlighted, setIsHighlighted] = useState(highlighted)

  useEffect(() => {
    setIsHighlighted(highlighted)
  }, [highlighted])

  const handleClick = () => {
    if (!suggestion || player !== "player") {
      return
    }

    if (isHighlighted) {
      // dispatch(playFlipSound())
      dispatch(gamesActions.move(gameId, suggestion))
    } else {
      setIsHighlighted(true)
      dispatch(highlightMove(gameId, suggestion))
    }
  }
  console.log("SuggestionButton", suggestion, isHighlighted)
  return (
    <ButtonBase
      sx={{
        "&:focus": {
          outlineWidth: 0,
        },
      }}
    >
      <FlippableSuggestionCard
        mobile
        onClick={() => handleClick()}
        boardWidth={boardWidth}
        highlighted={isHighlighted}
        suggestion={suggestion}
      />
    </ButtonBase>
  )
}

export default memo(SuggestionButton)
