import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { GameVariant, GameConfig, TimeControlsConfig } from "@all-good-apps/draft-chess-common"
import challengeActions from "../../userRequests/actions/challengeActions"
import playScreenUtils from "./playScreenUtils"

const useCreateChallengeRequest = (
  gameType: string | undefined,
  gameVariant: GameVariant,
  friend: string | undefined
) => {
  const timeControlsConfig = playScreenUtils.createTimeControlsConfig(gameType, gameVariant)
  const gameConfig: GameConfig = {
    timeControls: timeControlsConfig as TimeControlsConfig,
    variant: gameVariant,
  }
  const dispatch = useDispatch()
  return useCallback(() => {
    dispatch(challengeActions.sendChallenge(friend as string, gameConfig))
  }, [timeControlsConfig, friend])
}

export default useCreateChallengeRequest
