import { styled, Typography } from "@mui/material"
import { FC } from "react"
import Piece from "react-chess-pieces"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";

const ContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 35,
}))

const PiecesContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%", // asdasda
  paddingRight: theme.spacing(1),
  // backgroundColor: theme.palette.primary.main,
}))

type PeiceName = "pawn" | "rook" | "bishop" | "knight" | "queen" | "king"

interface Props {
  player: "player" | "opponent"
}
const MaterialScore: FC<Props> = ({ player }) => {
  const { materialScore, playerColor, opponentColor } = gameHooks.useGameContext()

  const color = player === "player" ? playerColor : opponentColor
  const score = color === "w" ? materialScore.white : materialScore.black

  const kings = score.peices?.filter((peice) => peice === "k") || []
  const otherPeices = score.peices?.filter((peice) => peice !== "k") || []

  return (
    <ContainerDiv>
      {kings.length > 0 && (
        <PiecesContainer>
          {kings.map((piece: string) => (
            <div className="material-advantage-piece-king center">
              <Piece piece={piece} />
            </div>
          ))}
        </PiecesContainer>
      )}
      {score.advantage > 0 && (
        <div style={{ paddingRight: 2 }}>
          <Typography variant="body2">+{score.advantage}</Typography>
        </div>
      )}
      <PiecesContainer>
        {otherPeices.map((piece: string) => (
          <div className="material-advantage-piece center">
            <Piece piece={piece} />
          </div>
        ))}
      </PiecesContainer>
    </ContainerDiv>
  )
}

export default MaterialScore
