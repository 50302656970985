import { TabContext } from "@mui/lab"
import { styled, Tab, Tabs } from "@mui/material"
import { useState } from "react"
import TemplatePage from "../../common/components/TemplatePage"
import InviteTab from "./InviteTab"
import PlayWithAFriendTab from "./PlayWithAFriendTab"
import QuickPairingTab from "./QuickPairingTab"

export const QUICK_PLAY_TAB = "QUICK_PLAY_TAB"
export const INVITE_TAB = "INVITE_TAB"
export const PLAY_WITH_A_FRIEND_TAB = "PLAY_WITH_A_FRIEND_TAB"

const RootDiv = styled("div")(({}) => ({
  flex: 1,
  display: "flex",
  paddingTop: 40,
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "column",
  height: "100%",
}))

const PlayPage = () => {
  const [selectedTab, setSelectedTab] = useState(QUICK_PLAY_TAB)
  return (
    <TemplatePage>
      <RootDiv>
        <TabContext value={selectedTab}>
          <div style={{ width: 400 }}>
            <Tabs onChange={(_, newValue) => setSelectedTab(newValue)} value={selectedTab} variant="fullWidth">
              <Tab label="Quick Play" value={QUICK_PLAY_TAB} />
              <Tab label="Invite" value={INVITE_TAB} />
              <Tab label="Friends" value={PLAY_WITH_A_FRIEND_TAB} />
            </Tabs>
            <QuickPairingTab />
            <InviteTab />
            <PlayWithAFriendTab />
          </div>
        </TabContext>
      </RootDiv>
    </TemplatePage>
  )
}

export default PlayPage
