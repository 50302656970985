import { ButtonBase, Paper, styled, Typography } from "@mui/material"
import Piece from "react-chess-pieces"

const PieceDiv = styled("div")(({}) => ({
  height: "40px",
  width: "45px",
  color: "gray",
}))

const TimeControlCard = ({
  value,
  selectedValue,
  onClick,
}: {
  value: string
  selectedValue: string | undefined
  onClick: Function
}) => {
  const isSelected = selectedValue === value
  return (
    <ButtonBase onClick={() => onClick(value)}>
      <Paper
        elevation={10}
        sx={{
          width: 80,
          height: 80,
          backgroundColor: (theme) => (isSelected ? theme.palette.primary.main : theme.palette.primary.light),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",

          boxShadow: "0px 3px 5px rgba(0, 0, 0, .5)",
        }}
        color="primary"
      >
        <PieceDiv>
          <Piece piece={selectedValue === value ? "n" : "N"} />
        </PieceDiv>
        <Typography variant="h6" color="secondary">
          {value}
        </Typography>
      </Paper>
    </ButtonBase>
  )
}

export default TimeControlCard
