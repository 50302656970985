import { Card, CardActions, CardContent, CardHeader, Fade } from "@mui/material"
import * as ChessJS from "chess.js"
import { useMemo } from "react"
import RematchRequestPanel from "../../userRequests/components/RematchRequestPanel"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";

const Chess = typeof ChessJS === "function" ? ChessJS : ChessJS.Chess

interface Props {
  width: number
}

const GameOverPanel = ({ width }: Props) => {
  const { gameResult } = gameHooks.useGameContext()

  const gameOverResultMessage = useMemo(() => {
    switch (gameResult?.result) {
      case "3check":
        return "3 checks!"
      case "checkmate":
        return "Checkmate!"
      case "timeout":
        return "Timeout"
      case "draw_by_agreement":
        return "Agreement"
      case "insuffienct_material":
        return "Insuffient Material"
      case "resignation":
        return "Resignation"
    }
  }, [gameResult])

  // TODO FIX THIS SOUND
  // useEffect(() => {
  //   const didPlayerWin = playerColor === gameResult?.winningColor ? true : false

  //   if (gameResult) {
  //     if (didPlayerWin) {
  //       dispatch(playSound("win"))
  //     } else {
  //       dispatch(playSound("lose"))
  //     }
  //   }
  // }, [gameResult])

  if (!gameResult) {
    return null
  }

  return (
    <Fade
      in={true}
      timeout={{
        enter: 2000,
        exit: 1000,
      }}
    >
      <Card
        style={{
          width: width * 0.92,
          position: "absolute",
          display: "flex",
          zIndex: 10000,
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          top: "29%",
          left: "4%",
        }}
      >
        <CardHeader
          title={!gameResult.isDraw ? (gameResult.winningColor === "b" ? "Black won" : "White won") : "Draw"}
        ></CardHeader>

        <CardContent>by {gameOverResultMessage}</CardContent>

        <CardActions>
          <RematchRequestPanel />
        </CardActions>
      </Card>
    </Fade>
  )
}

export default GameOverPanel
