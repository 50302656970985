import {
  GameVariant,
  TimeControlsConfig,
  _1D,
  _1M,
  _1S,
  _2S,
  _30S,
  _3D,
  _3M,
  _3S,
  _5D,
  _5M,
} from "@all-good-apps/draft-chess-common";

const createTimeControlsConfig = (
  gameType: string | undefined,
  gameVariant: GameVariant
) => {
  let timeControlsConfig: Partial<TimeControlsConfig>;
  switch (gameType) {
    case "30s":
      timeControlsConfig = {
        type: "daily",
        moveTime: _30S,
      };
      break;
    case "1m":
      timeControlsConfig = {
        type: "daily",
        moveTime: _1M,
      };
      break;
    case "3m":
      timeControlsConfig = {
        type: "daily",
        moveTime: _3M,
      };
      break;
    case "1m":
      timeControlsConfig = {
        type: "standard",
        totalTime: _1M,
        increment: 0,
      };
      break;
    case "3m":
      timeControlsConfig = {
        type: "standard",
        totalTime: _3M,
        increment: 0,
      };
      break;
    case "5m":
      timeControlsConfig = {
        type: "standard",
        totalTime: _5M,
        increment: 0,
      };
      break;
    case "1m+1s":
      timeControlsConfig = {
        type: "standard",
        totalTime: _1M,
        increment: _1S,
      };
      break;
    case "3m+2s":
      timeControlsConfig = {
        type: "standard",
        totalTime: _3M,
        increment: _2S,
      };
      break;
    case "5m+3s":
      timeControlsConfig = {
        type: "standard",
        totalTime: _5M,
        increment: _3S,
      };
      break;
    case "1d":
      timeControlsConfig = {
        type: "daily",
        moveTime: _1D,
      };
      break;
    case "3d":
      timeControlsConfig = {
        type: "daily",
        moveTime: _3D,
      };
      break;
    case "5d":
      timeControlsConfig = {
        type: "daily",
        moveTime: _5D,
      };
      break;
    default:
      timeControlsConfig = {
        type: "daily",
        moveTime: _1D,
      };
      break;
  }

  timeControlsConfig.readableName = gameType;

  return timeControlsConfig as TimeControlsConfig;
};

export default {
  createTimeControlsConfig,
};
