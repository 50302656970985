import { PrivateProfile, PublicProfile, User } from "@all-good-apps/draft-chess-common"

export const UPDATE_PUBLIC_USER_INFO_ACTION = "profile/UPDATE_PUBLIC_USER_INFO_ACTION"
export const UPDATE_PRIVATE_USER_INFO_ACTION = "profile/UPDATE_PRIVATE_USER_INFO_ACTION"
export const UPDATE_FRIEND_INFO_ACTION = "friendRequest/UPDATE_FRIEND_INFO_ACTION"
export const UPDATE_DISPLAY_NAME_ACTION = "profile/UPDATE_DISPLAY_NAME_ACTION"

const updatePublicUserInfo = (profile: PublicProfile) => ({
  type: UPDATE_PUBLIC_USER_INFO_ACTION,
  payload: { profile },
})

const updatePrivateUserInfo = (profile: PrivateProfile) => ({
  type: UPDATE_PRIVATE_USER_INFO_ACTION,
  payload: { profile },
})

const updateFriends = (profiles: PublicProfile[]) => ({
  type: UPDATE_FRIEND_INFO_ACTION,
  payload: { profiles },
})

const updateDisplayName = (displayName: string) => ({
  type: UPDATE_DISPLAY_NAME_ACTION,
  payload: { displayName },
})

export type UpdatePublicUserInfoAction = ReturnType<typeof updatePublicUserInfo>
export type UpdatePrivateUserInfoAction = ReturnType<typeof updatePrivateUserInfo>
export type UpdateFriendInfoAction = ReturnType<typeof updateFriends>
export type UpdateDisplayNameAction = ReturnType<typeof updateDisplayName>

export default {
  updatePublicUserInfo,
  updatePrivateUserInfo,
  updateFriends,
  updateDisplayName,
}
