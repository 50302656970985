import { ref } from "firebase/database"
import { database } from "../../../firebase/admin"

const highlightedMove = (gameId: string) => {
  return ref(database, "highlightedMove/" + gameId)
}

export default {
  highlightedMove,
}
