import { ThemeProvider } from "@mui/material"
import { FC } from "react"
import { Provider } from "react-redux"
import "./App.css"
import AuthGate from "./features/common/components/AuthGate"
import theme from "./features/common/theme"
import SnackbarDispatcher from "./features/snackbar/components/SnackbarDispatcher"
import store from "./store"

const App: FC = () => {
  return (
    <Provider store={store}>
      <SnackbarDispatcher />
      <ThemeProvider theme={theme}>
        <AuthGate />
      </ThemeProvider>
    </Provider>
  )
}

export default App
