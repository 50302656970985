import { Typography } from "@mui/material"

type Props = {
  rating: number
}

const EloRatingValue = (props: Props) => {
  return <Typography variant="body1">{props.rating}</Typography>
}

export default EloRatingValue
