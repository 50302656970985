import Chessboard from "chessboardjsx"
import { memo } from "react"
import { PlayerColor } from "@all-good-apps/draft-chess-common"
import { boardColors } from "../../common/theme"
import ChallengeItem from "../../userRequests/components/ChallengeItem"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";
import useSquareStyles from "../hooks/useSquareStyles"
import GameOverPanel from "./GameOverPanel"

interface Props {
  width?: number
}

const getOrientation = (playerColor: PlayerColor | undefined) => {
  switch (playerColor) {
    case "w":
      return "white"
    case "b":
      return "black"
    default:
      return playerColor
  }
}

const ChessGameBoard = memo((props: Props) => {
  const { width = 200 } = props
  const { gameId, gameStatus, playerColor, isGameDataLoading, isPlayersTurn } = gameHooks.useGameContext()
  const { fen } = gameStatus
  const squareStyles = useSquareStyles()

  // const lastMoveRef = useRef<string | null>(null)
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   if (isGameDataLoading) return

  //   if (lastMoveRef.current === null) {
  //     lastMoveRef.current = lastMove?.lan || ""
  //     return
  //   }

  //   if (lastMoveRef.current === lastMove?.lan || !lastMove) return

  //   // Play check sound if move is check or checkmate
  //   if (lastMove.san.includes("+") || lastMove.san.includes("#")) {
  //     dispatch(playSound("check", 200))
  //   } else if (lastMove.captured) {
  //     dispatch(playSound("capture", 200))
  //   } else {
  //     dispatch(playSound("move", 200))
  //   }
  // }, [lastMove?.lan, isGameDataLoading])

  // console.log(gameId, fen, playerColor, isPlayersTurn)
  return (
    <div
      style={{
        position: "relative",
        borderRadius: 6,
        overflow: "hidden",
        width: width,
        height: width,
        minHeight: width,
        boxShadow: "0 0 10px rgba(0, 0, 0, .4)",
      }}
    >
      <ChallengeItem gameId={gameId} width={width} />
      <GameOverPanel width={width} />
      <Chessboard
        orientation={getOrientation(playerColor)}
        showNotation={true}
        position={fen}
        draggable={false}
        transitionDuration={250}
        width={width}
        squareStyles={squareStyles}
        lightSquareStyle={{ backgroundColor: boardColors.lightSquare }}
        darkSquareStyle={{ backgroundColor: boardColors.darkSquare }}
      />
    </div>
  )
})

export default ChessGameBoard
