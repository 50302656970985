import { Google } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Button, Divider, Link, Paper, Stack, TextField, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { authActions, authSelectors } from "@all-good-apps/draft-chess-client-api"
import ForgotPasswordModal from "./ForgotPasswordModal"

interface Props {
  onClose: () => void
  mode: "signIn" | "createAccount"
}

const SignInPage = ({ mode, onClose }: Props) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const dispatch = useDispatch()

  const handleLogIn = () => {
    dispatch(
      mode === "signIn"
        ? authActions.logInEmail(email, password)
        : authActions.upgradeWithEmailAndPassword(email, password)
    )
    onClose()
  }

  const handleForgotPasswordModalOpen = () => {
    dispatch(
      authActions.update({
        isForgotPasswordModalActive: true,
      })
    )
  }

  const handleGoogleLogIn = useCallback(() => {
    dispatch(mode === "signIn" ? authActions.logInWithGoogle() : authActions.upgradeWithGoogle())
    onClose()
  }, [dispatch])

  const authenticating = useSelector(authSelectors.authenticating)

  return (
    <Paper
      sx={(theme) => ({
        position: "absolute",
        top: "30%",
        left: "50%",
        backgroundColor: theme.palette.background.default,
        width: 400,
        marginTop: theme.spacing(8),
        padding: theme.spacing(2),
        transform: "translate(-50%, -50%)",
      })}
    >
      <Stack spacing={2} alignItems={"center"}>
        <img
          src="/draftChessIcon.png"
          style={{
            width: 200,
            height: 200,
          }}
        />
        <Typography component="h1" variant="h5">
          {mode === "signIn" ? "Sign in" : "Create Account"}
        </Typography>
        <TextField
          variant="outlined"
          color="primary"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(event) => setEmail(event.currentTarget.value)}
        />
        <TextField
          variant="outlined"
          color="primary"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(event) => setPassword(event.currentTarget.value)}
        />
        <Divider light />
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogIn}
          disabled={!(email && password)}
          loading={authenticating}
        >
          {mode === "signIn" ? "Sign In" : "Create Account"}
        </LoadingButton>
        {mode === "signIn" && (
          <Typography variant="body2" color="textPrimary" align="center">
            <Link component="button" variant="body2" onClick={handleForgotPasswordModalOpen}>
              Forgot password?
            </Link>
          </Typography>
        )}
        <ForgotPasswordModal />
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleGoogleLogIn}
          disabled={authenticating}
          startIcon={<Google />}
        >
          {mode === "signIn" ? "Sign In" : "Create Account"} with Google
        </Button>
      </Stack>
    </Paper>
  )
}

export default SignInPage
