import { LinearProgress, Tooltip, useTheme } from "@mui/material"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";

const TOOL_TIP_MESSAGE = "Chess Engine evaluation: "

type Props = { boardWidth: number }

const EvaluationBar = ({ boardWidth }: Props) => {
  const { evaluation, playerColor, isGameDataLoading, status, gameResult } = gameHooks.useGameContext()

  const clampedEvaluation = Math.max(-20, Math.min(20, evaluation))
  const value = ((clampedEvaluation + 20) / 40) * 100

  const clampedValue = Math.max(10, Math.min(90, value))

  let gameOverValue = null
  if (gameResult) {
    gameOverValue = gameResult.isDraw ? 50 : gameResult.winningColor === "w" ? 100 : 0
  }

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: boardWidth,
      }}
    >
      <Tooltip title={TOOL_TIP_MESSAGE + evaluation}>
        <LinearProgress
          sx={(theme) => ({
            transformOrigin: "center",
            transform: `rotate(${playerColor === "w" ? "-" : ""}90deg)`,
            backgroundColor: "#696969",
            color: "white",
            height: 8,
            width: boardWidth - 32,
            borderRadius: 8,
            top: boardWidth / 2,
            right: boardWidth / 2,
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: "white",
            },
            "& .MuiLinearProgress-bar": {
              transition: "ease-out 1.00s",
            },
          })}
          value={isGameDataLoading ? 50 : gameOverValue !== null ? gameOverValue : clampedValue}
          variant={"determinate"}
        />
      </Tooltip>
    </div>
  )
}

export default EvaluationBar
