import { call, put, select, takeLatest } from "redux-saga/effects"
import { gameDataSelectors, gamesActions } from "@all-good-apps/draft-chess-client-api"
import { AxiosResponse } from "axios"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { RealtimeGameData } from "@all-good-apps/draft-chess-common"

export const REFRESH_SUGGESTIONS_ACTION = `games/REFRESH_SUGGESTIONS_ACTION`
export const refreshSuggestions = (gameId: string) => ({
  type: REFRESH_SUGGESTIONS_ACTION,
  payload: {
    gameId,
  },
})
export type RefreshSuggestionAction = ReturnType<typeof refreshSuggestions>

function* handleRefreshSuggestions(action: RefreshSuggestionAction) {
  const { gameId } = action.payload
  const gameData: RealtimeGameData = yield select(gameDataSelectors.gameDataById, gameId)

  const newSuggestions = gameData.remainingSuggestions.slice(0, 3)
  const remainingSuggestions = gameData.remainingSuggestions.slice(3)

  yield put(
    gamesActions.updateGameData({
      ...gameData,
      activeSuggestions: newSuggestions,
      remainingSuggestions,
    })
  )

  try {
    const response: AxiosResponse = yield call(authHelpers.authenticatedRequest, {
      method: "POST",
      url: `game/${gameId}/refreshSuggestions`,
    })
    const { status, data } = response

    if (status === 200) {
      yield put(gamesActions.updateGameData(data))
    } else {
      // if there is a problem with the move, revert the optimistic change
      yield put(gamesActions.updateGameData(gameData))
    }
  } catch (e) {
    console.log(e)
    // if there is a problem with the move, revert the optimistic change
    yield put(gamesActions.updateGameData(gameData))
  }
}

export default takeLatest(REFRESH_SUGGESTIONS_ACTION, handleRefreshSuggestions)
