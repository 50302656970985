import { Square } from "chess.js"
import { CSSProperties, useMemo } from "react"
import { useSelector } from "react-redux"
import { selectSuggestionsState } from "../../suggestions/reducers/suggestionsByGameIdReducer"
import { RootState } from "../../../store"
import { boardColors } from "../../common/theme"
import { gameDataSelectors, gameHooks } from "@all-good-apps/draft-chess-client-api";

const SQUARES: Square[] = [
  "a8",
  "b8",
  "c8",
  "d8",
  "e8",
  "f8",
  "g8",
  "h8",
  "a7",
  "b7",
  "c7",
  "d7",
  "e7",
  "f7",
  "g7",
  "h7",
  "a6",
  "b6",
  "c6",
  "d6",
  "e6",
  "f6",
  "g6",
  "h6",
  "a5",
  "b5",
  "c5",
  "d5",
  "e5",
  "f5",
  "g5",
  "h5",
  "a4",
  "b4",
  "c4",
  "d4",
  "e4",
  "f4",
  "g4",
  "h4",
  "a3",
  "b3",
  "c3",
  "d3",
  "e3",
  "f3",
  "g3",
  "h3",
  "a2",
  "b2",
  "c2",
  "d2",
  "e2",
  "f2",
  "g2",
  "h2",
  "a1",
  "b1",
  "c1",
  "d1",
  "e1",
  "f1",
  "g1",
  "h1",
]

function chessNotationToIndex(notation: string): number {
  const letters = "abcdefgh"
  const row = parseInt(notation[1], 10)
  const col = letters.indexOf(notation[0])

  // Convert to zero-based row index by subtracting 1 from row
  const rowIndex = 8 - row
  // Calculate the index in a one-dimensional array
  const index = rowIndex * 8 + col

  return index
}

/**
 * This is a map with styles for each squre of a chess,
 *
 * keys use san-like notation, e.g. a1...h8
 *
 * e.g.
 * {
 *    e4: {
 *      backgroundColor: "lightblue"
 *    }
 *
 * }
 */
type SquareStyles = { [square in Square]?: CSSProperties }

/**
 * Returns a map of style for squares of a chess board
 * Can be used as a prop for chessboardjs
 * @param gameId
 * @returns
 */
const useSquareStyles = (): SquareStyles => {
  const { gameId, isGameDataLoading, turn, gameStatus } = gameHooks.useGameContext()
  const { isCheck, squares, moveHistory } = gameStatus

  const side = turn === "w" ? "white" : "black"

  const suggestionState = useSelector((state: RootState) => selectSuggestionsState(state, gameId))
  const suggestions: string[] = useSelector((state: RootState) => gameDataSelectors.activeSuggestions(state, gameId)) || []

  return useMemo(() => {
    const { highlightedMove, showHighlights } = suggestionState || {}

    const squareStyles: { [square in Square]?: CSSProperties } = {}

    // const lastMove = moveStack.split(" ").pop() || ""

    const lastMove = moveHistory?.length > 0 ? moveHistory[moveHistory.length - 1] : null
    /**
     * Iterate over all squares in a chess board,
     * for each square, check if we want to add any special styles
     *
     * Squares that are the origin("from") or distnation("to") of a suggested move get
     * a set background color. Highlighted moves get a different color
     *
     */
    SQUARES.forEach((s) => {
      const hasSuggestionFrom = suggestions.some((suggestion) => suggestion.includes(s))
      const hasSuggestionTo = suggestions.some((suggestion) => suggestion.includes(s))
      const isHighlighted = highlightedMove?.to === s || highlightedMove?.from === s

      const isLastMoveSquare =
        (lastMove?.postFile === s[0] && lastMove?.postRank.toString() === s[1]) ||
        (lastMove?.prevFile === s[0] && lastMove?.prevRank.toString() === s[1])

      let backgroundColor = "rgba(0, 0, 0, 0)"

      switch (true) {
        case isHighlighted && showHighlights:
          backgroundColor = boardColors.higlightedSuggestion
          break
        case isCheck &&
          squares[chessNotationToIndex(s)]?.piece?.notation === "K" &&
          squares[chessNotationToIndex(s)]?.piece?.side.name !== side:
          backgroundColor = boardColors.inCheck
          break
        case (hasSuggestionTo || hasSuggestionFrom) && showHighlights:
          backgroundColor = boardColors.suggestion
          break
        // case highlightedMove?.san.includes("+") && boardMap[s]?.type === "k" && boardMap[s]?.color !== turn:
        //   // if the move is a check
        //   // backgroundColor = boardColors.inCheck
        //   // is the square has a king on it

        //   backgroundColor = boardColors.suggestionInCheck

        //   break
        case isLastMoveSquare:
          backgroundColor = boardColors.lastMovePlayer
          break
      }

      squareStyles[s] = {
        backgroundColor,
        transition: "background-color .3s ease-out",
      }
    })

    return squareStyles
  }, [suggestionState, suggestions, isGameDataLoading])
}

export default useSquareStyles
