import { Search } from "@mui/icons-material"
import { LoadingButton, TabPanel } from "@mui/lab"
import { Card, CardActions, CardContent, FormControl, InputLabel, Stack } from "@mui/material"
import { GameConfig, GameVariant, matchmakingUtils } from "@all-good-apps/draft-chess-common"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectAddingToMatchmakingPool, selectInPool } from "../../matchmaking/reducers/matchmakingUIReducer"
import { addToMatchmakingPool } from "../../matchmaking/sagas/handleAddToMatchMakingPool"
import { removeFromMatchmakingPool } from "../../matchmaking/sagas/handleRemoveFromMatchmakingPool"
import GameVariantSelect from "./GameVariantSelect"
import { QUICK_PLAY_TAB } from "./PlayPage"
import TimeControlSelector from "./TimeControlSelector"

interface QuickPairButtonProps {
  gameConfig: GameConfig
}

const QuickPairButton = ({ gameConfig }: QuickPairButtonProps) => {
  const inPool = useSelector(selectInPool)
  const addingToPool = useSelector(selectAddingToMatchmakingPool)

  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(removeFromMatchmakingPool(gameConfig))
    }
  }, [dispatch])

  const handleClick = () => {
    dispatch(!inPool ? addToMatchmakingPool(gameConfig) : removeFromMatchmakingPool(gameConfig))
  }

  return (
    <LoadingButton
      variant={inPool ? "outlined" : "contained"}
      color="primary"
      loading={addingToPool}
      fullWidth
      onClick={handleClick}
      startIcon={<Search />}
    >
      {!inPool ? "Find Game" : "Searching..."}
    </LoadingButton>
  )
}

const QuickPairingTab = () => {
  const [value, setValue] = useState("1m+10s")
  const [selectedGameVariant, setSelectGameVariant] = useState<GameVariant>("3check")

  return (
    <TabPanel sx={{ padding: 0, width: "100%" }} value={QUICK_PLAY_TAB}>
      <Card>
        <CardContent>
          <Stack direction={"column"} justifyContent="center" alignItems={"center"} spacing={4}>
            <TimeControlSelector value={value} onTimeControlSelected={setValue} />
            <FormControl fullWidth>
              <InputLabel>Game Variant</InputLabel>
              <GameVariantSelect
                selectedGameVariant={selectedGameVariant}
                setSelectGameVariant={setSelectGameVariant}
              />
            </FormControl>
          </Stack>
        </CardContent>
        <CardActions>
          <QuickPairButton
            gameConfig={{
              variant: selectedGameVariant,
              timeControls: matchmakingUtils.getTimeControlsConfigFromReadableName(value),
            }}
          />
        </CardActions>
      </Card>
    </TabPanel>
  )
}

export default QuickPairingTab
