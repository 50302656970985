import { AccountCircleSharp } from "@mui/icons-material"

export const menuItems = [
  {
    path: "/settings",
    icon: <AccountCircleSharp color="primary" />,
  },
  {
    text: "Play",
    path: "/play",
  },
  {
    text: "Games",
    path: "/games",
  },
]
