import { get, ref } from "firebase/database"
import { put, takeLatest } from "redux-saga/effects"
import { database } from "../../../firebase/admin"
import { updateInviteState } from "../reducers/invitesReducer"
import { updateInviteUIState } from "../reducers/inviteUIReducer"

export const FETCH_INVITE = `invite/FETCH_INVITE`

export const fetchInvite = (inviteId: string) => ({
  type: FETCH_INVITE,
  payload: {
    inviteId,
  },
})

export type FetchInviteAction = ReturnType<typeof fetchInvite>

function* handleFetch(action: FetchInviteAction): any {
  try {
    const { inviteId } = action.payload

    yield put(updateInviteUIState({ fetchingInvite: true, fetchInviteError: undefined }))

    const inviteRef = ref(database, "invite/" + inviteId)

    const snapshot = yield get(inviteRef)

    const invite = snapshot.val()

    yield put(updateInviteUIState({ fetchingInvite: true }))
    yield put(updateInviteState(invite))
  } catch (e: any) {
    yield put(updateInviteUIState({ fetchInviteError: e.message }))
  } finally {
    yield put(updateInviteUIState({ fetchingInvite: false }))
  }
}

export default takeLatest(FETCH_INVITE, handleFetch)
