import { ArrowLeft } from "@mui/icons-material"
import { Button, Chip, styled } from "@mui/material"
import { FC } from "react"
import { useDispatch, useSelector } from "react-redux"
import { gameUISelectors } from "@all-good-apps/draft-chess-client-api"
import { navigate } from "../../history/historyReducer"
import MobileMenu from "./MobileMenu"
import TopRightMenu from "./TopRightMenu"

const RootDiv = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100%",
  flexDirection: "column",
  // flexWrap: "wrap",
}))

const TopDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",

  height: 70,
  // position: "absolute",
}))

const ContentDiv = styled("div")(({ theme }) => ({
  flex: 1,
  displa: "flex",
}))

interface Props {
  onGamePage?: boolean
  children: React.ReactNode
}

const MobileTemplatePage: FC<Props> = ({ onGamePage, children }) => {
  const dispatch = useDispatch()
  const numberOfMoves = useSelector(gameUISelectors.selectNumberOfMovesToPlay)

  return (
    <RootDiv>
      <TopDiv>
        {onGamePage ? (
          <Button
            color="primary"
            variant="text"
            startIcon={<ArrowLeft />}
            size="small"
            sx={{ margin: (theme) => theme.spacing(2) }}
            onClick={() => dispatch(navigate("/games"))}
          >
            Games
            {numberOfMoves > 0 && <Chip label={numberOfMoves} color="primary" size="small" sx={{ marginLeft: 1 }} />}
          </Button>
        ) : (
          <div />
        )}
        <TopRightMenu />
      </TopDiv>
      <ContentDiv>{children}</ContentDiv>
      {!onGamePage && <MobileMenu />}
    </RootDiv>
  )
}

export default MobileTemplatePage
