import { EventChannel, eventChannel } from "redux-saga"
import { put, race, select, take } from "redux-saga/effects"
import { firestore } from "../../../firebase/admin"
import { authActions, authSelectors } from "@all-good-apps/draft-chess-client-api"
import profileActions from "../actions/profileActions"
import { doc, onSnapshot } from "firebase/firestore"
import { PrivateProfile } from "@all-good-apps/draft-chess-common"

const privateProfileChannel = (uid: string): EventChannel<PrivateProfile> => {
  return eventChannel<PrivateProfile>((emit) => {
    const collectionRef = doc(firestore, "users", uid as string)

    return onSnapshot(collectionRef, (snapshot) => {
      const request = snapshot.data()
      if (request) {
        emit(request as PrivateProfile)
      }
    })
  })
}

function* listenForChanges(uid: string) {
  const channel: EventChannel<PrivateProfile> = yield privateProfileChannel(uid)
  while (true) {
    const updatedUser: PrivateProfile = yield take(channel)
    yield put(profileActions.updatePrivateUserInfo(updatedUser))
  }
}

function* listenForPrivateProfile() {
  while (true) {
    yield take(authActions.USER_LOGGED_IN)
    const uid: string = yield select(authSelectors.uid)
    yield race([listenForChanges(uid), take(authActions.USER_LOGGED_OUT)])
  }
}

export default listenForPrivateProfile
