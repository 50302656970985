import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import profileSelector from "../selectors/profileSelector"
import { PublicProfile } from "@all-good-apps/draft-chess-common"
import { RootState } from "../../../store"
import { fetchPublicProfiles } from "../sagas/handleFetchPublicProfile"

const EMPTY_PROFILE: PublicProfile = {
  uid: "",
  displayName: "",
  friends: [],
  rating: 1500,
}

const usePublicProfile = (uid: string): PublicProfile => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchPublicProfiles([uid]))
  }, [uid])

  const publicProfile = useSelector((state: RootState) => profileSelector.friendInfoById(state, uid))

  return publicProfile || EMPTY_PROFILE
}

export default usePublicProfile
