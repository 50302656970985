import { UpdateFriendInfoAction, UPDATE_FRIEND_INFO_ACTION } from "../actions/profileActions"
import { Reducer } from "redux"
import { keyBy } from "lodash-es"
import { PublicProfile } from "@all-good-apps/draft-chess-common"

export interface userPublicProfileByIdReducer {
  ids: string[]
  byId: {
    [uid: string]: PublicProfile
  }
}

const initialState: userPublicProfileByIdReducer = {
  ids: [],
  byId: {},
}

const userPublicProfileByIdReducer: Reducer<userPublicProfileByIdReducer, UpdateFriendInfoAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_FRIEND_INFO_ACTION:
      const { profiles } = action.payload

      const newById = {
        ...state.byId,
        ...keyBy(profiles, "uid"),
      }

      const newIds = Object.keys(newById)

      return {
        ...state,
        ids: newIds,
        byId: newById,
      }
    default:
      return state
  }
}

export default userPublicProfileByIdReducer
