import { DatabaseReference, off, onValue } from "firebase/database"
import firebase from "firebase/app"
import { eventChannel } from "redux-saga"

export type RealtimeDatabaseDocChangesChannel = import("redux-saga").EventChannel<any>

export const REMOVED = "REMOVED"

function realtimeDatabaseDocChangesChannel(ref: DatabaseReference): RealtimeDatabaseDocChangesChannel {
  return eventChannel((emit) => {
    onValue(ref, (snapshot) => {
      emit(snapshot.val() || REMOVED)
    })

    return () => {
      off(ref)
    }
  })
}

export default realtimeDatabaseDocChangesChannel
