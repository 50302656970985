import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Route, Routes } from "react-router-dom"
import ForgotPasswordPage from "../../auth/components/ForgotPasswordPage"
import GamePage from "../../games/components/GamePage"
import GamesPage from "../../games/components/GamesPage"
import { navigate } from "../../history/historyReducer"
import InvitePage from "../../invite/components/InvitePage"
import NewInvitePage from "../../invite/components/NewInvitePage"
import Leaderboard from "../../leaderboard/components/Leaderboard"
import PlayPage from "../../playScreen/components/PlayPage"
import SettingsPage from "../../profile/components/SettingsPage"
import ThemeTest from "../pages/ThemeTest"

const WorkingNaviage = (props: { to: string }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(navigate(props.to))
  }, [dispatch, props.to])
  return <div />
}

const BaseRoutes = () => {
  return (
    <Routes>
      <Route path="play" element={<PlayPage />} />
      <Route path="games/:gameId" element={<GamePage />} />
      <Route path="games" element={<GamesPage />} />
      <Route path="forgotpw" element={<ForgotPasswordPage />} />
      <Route path="settings" element={<SettingsPage />} />
      <Route path="newInvite/:inviteId" element={<NewInvitePage />} />
      <Route path={`invite/:inviteId`} element={<InvitePage />} />
      <Route path="test" element={<ThemeTest />} />
      <Route path="leaderboard" element={<Leaderboard />} />
      <Route path="/" element={<WorkingNaviage to="/play" />} />
    </Routes>
  )
}
export default BaseRoutes
