import { styled } from "@mui/material"
import Stack from "@mui/material/Stack"
import EloRatingLabel from "../../atoms/labels/EloRatingLabel"
import EloRatingValue from "../../atoms/values/EloRatingValue"

type Props = {
  rating: number
}

const RootDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
}))

const EloRatingInformation = (props: Props) => {
  return (
    <RootDiv>
      <Stack
        spacing={1}
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        <EloRatingLabel />
      </Stack>
      <Stack
        spacing={1}
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        <EloRatingValue rating={props.rating} />
      </Stack>
    </RootDiv>
  )
}

export default EloRatingInformation
