import { LeaderboardItem } from "@all-good-apps/draft-chess-common"

export const GET_LEADERBOARD_ACTION = "leaderboard/GET_LEADERBOARD_ACTION"
export const UPDATE_LEADERBOARD_ACTION = "leaderboard/SHOW_LEADERBOARD_ACTION"

const getLeaderboard = () => ({
  type: GET_LEADERBOARD_ACTION,
})

const updateLeaderboard = (leaderboard: LeaderboardItem[]) => ({
  type: UPDATE_LEADERBOARD_ACTION,
  payload: { leaderboard },
})

export type GetLeaderboardAction = ReturnType<typeof getLeaderboard>
export type UpdateLeaderboardAction = ReturnType<typeof updateLeaderboard>

export default {
  getLeaderboard,
  updateLeaderboard,
}
