import { useDispatch, useSelector } from "react-redux"
import ForgotPasswordPage from "./ForgotPasswordPage"
import { Modal } from "@mui/material"
import { authActions, authSelectors } from "@all-good-apps/draft-chess-client-api"

const ForgotPasswordModal = () => {
  const dispatch = useDispatch()

  const handleForgotPasswordModalClose = () => {
    dispatch(
      authActions.update({
        isForgotPasswordModalActive: false,
      })
    )
  }

  const { isForgotPasswordModalActive } = useSelector(authSelectors.authState)

  return (
    <Modal
      open={isForgotPasswordModalActive}
      onClose={handleForgotPasswordModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ForgotPasswordPage />
    </Modal>
  )
}

export default ForgotPasswordModal
