import { DraftChessJS } from "@all-good-apps/draft-chess-common"
import { Move } from "chess.js"
import React, { ReactNode, useMemo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../store"
import { gameHooks, gamesUtils } from "@all-good-apps/draft-chess-client-api";
import { selectHighlightedMove } from "../reducers/suggestionsByGameIdReducer"

type Props = {
  player?: "player" | "opponent"
  children: ReactNode
}

export interface SuggestionContextData {
  suggestions: (Move | undefined)[]
  player: "player" | "opponent"
  hasRemainingSuggestions: boolean
  highlightedMove: Move | undefined
  active: boolean
}

const DEFAULT_SUGGESTION_DATA: SuggestionContextData = {
  suggestions: [undefined, undefined, undefined],
  player: "player",
  hasRemainingSuggestions: false,
  highlightedMove: undefined,
  active: false,
}

export const SuggestionContext = React.createContext<SuggestionContextData>(DEFAULT_SUGGESTION_DATA)

const convertToChessJSMoves = (fen: string, moves: string[]): Move[] => {
  const chess = new DraftChessJS(fen)
  const chessMoves = chess.moves({ verbose: true }) as Move[]
  console.dir(chessMoves)
  return moves
    .filter((move) => move.length > 0)
    .map((move) => {
      const from = move.slice(0, 2)
      const to = move.slice(2, 4)
      return chessMoves.find((m) => m.from === from && m.to === to)
    }) as Move[]
}

const SuggestionContextProvider = (props: Props) => {
  const { children, player = "player" } = props
  const {
    gameId,
    gameStatus,
    activeSuggestions,
    remainingSuggestions,
    isPlayersTurn,
    isGameDataLoading,
    playerColor,
    opponentColor,
  } = gameHooks.useGameContext()

  const { fen } = gameStatus

  const highlightedMove = useSelector((state: RootState) => selectHighlightedMove(state, gameId))

  const value: SuggestionContextData = useMemo(() => {
    const showSuggestions = player === "player" ? isPlayersTurn : !isPlayersTurn

    const chessJSSuggestions = convertToChessJSMoves(fen, activeSuggestions)

    const suggestions = Array(3)
      .fill(undefined)
      .map((_, index) => {
        return showSuggestions ? chessJSSuggestions[index] : undefined
      })

    return {
      suggestions: gamesUtils.sortSuggestionsForUI(suggestions, player === "player" ? playerColor : opponentColor, isPlayersTurn),
      player,
      hasRemainingSuggestions: remainingSuggestions.length > 0,
      highlightedMove,
      active: showSuggestions,
    }
  }, [
    gameId,
    activeSuggestions,
    remainingSuggestions,
    isPlayersTurn,
    highlightedMove,
    player,
    playerColor,
    opponentColor,
  ])

  return <SuggestionContext.Provider value={value}>{children}</SuggestionContext.Provider>
}

export default SuggestionContextProvider
