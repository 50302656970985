import { Done } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Card, Typography, styled } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { PublicProfile, UserEventStatus } from "@all-good-apps/draft-chess-common"
import { RootState } from "../../../store"
import { authSelectors, gameRecordSelectors } from "@all-good-apps/draft-chess-client-api"
import profileSelector from "../../profile/selectors/profileSelector"
import challengeActions from "../actions/challengeActions"
import { selectChallengeLoading } from "../reducers/userRequestsUIReducer"

const ACCEPTED: UserEventStatus = "accepted"
const DISMISSED: UserEventStatus = "dismissed"

interface Props {
  gameId: string
  width: number
}

const RequestInfoDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItem: "center",
}))

const ButtonContainerDiv = styled("div")(({ theme }) => ({
  paddingRight: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  width: "100%",
}))

const AcceptDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
}))

const DismissDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  textDecoration: "underline",
  paddingLeft: theme.spacing(2),
  alignItems: "center",
}))

const ChallengeItem = ({ gameId, width }: Props) => {
  const gameRecord = useSelector((state: RootState) => gameRecordSelectors.gameRecordById(state, gameId))

  const dispatch = useDispatch()

  const uid = useSelector(authSelectors.uid)

  const opponentUid = uid !== gameRecord?.to ? gameRecord?.to : gameRecord?.from

  const friendInfo: PublicProfile | undefined = useSelector((state: RootState) =>
    profileSelector.friendInfoById(state, opponentUid || "")
  )
  const fromName = friendInfo?.displayName

  const respondToChallengeLoading = useSelector(selectChallengeLoading)
  if (!gameRecord || gameRecord.status !== "not_started") {
    return null
  }
  const userHasIssuedChallenge: boolean = gameRecord.from === uid

  const message = userHasIssuedChallenge
    ? `You have issued a challenge to ${fromName}`
    : `${fromName} challenges you to a game of Draft Chess!`

  return (
    <Card
      style={{
        width: width * 0.92,
        height: width * 0.42,
        position: "absolute",
        display: "flex",
        zIndex: 10000,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        top: "29%",
        left: "4%",
      }}
    >
      <RequestInfoDiv>
        <Typography variant="h6">{message}</Typography>
      </RequestInfoDiv>
      <div>
        <Typography>{gameRecord.gameConfig.timeControls.readableName}</Typography>
      </div>
      <ButtonContainerDiv>
        {userHasIssuedChallenge ? (
          <>
            <DismissDiv>
              <LoadingButton
                variant="text"
                onClick={() => dispatch(challengeActions.respondToChallenge(gameId, DISMISSED))}
                loading={respondToChallengeLoading}
              >
                Cancel Challenge
              </LoadingButton>
            </DismissDiv>
          </>
        ) : (
          <>
            <AcceptDiv>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={() => dispatch(challengeActions.respondToChallenge(gameId, ACCEPTED))}
                startIcon={<Done />}
                size="small"
                loading={respondToChallengeLoading}
              >
                Accept
              </LoadingButton>
            </AcceptDiv>
            <DismissDiv>
              <LoadingButton
                color="secondary"
                variant="text"
                onClick={() => dispatch(challengeActions.respondToChallenge(gameId, DISMISSED))}
                loading={respondToChallengeLoading}
              >
                Cancel Challenge
              </LoadingButton>
            </DismissDiv>
          </>
        )}
      </ButtonContainerDiv>
    </Card>
  )
}

export default ChallengeItem
