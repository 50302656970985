import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import {
  faChessBishop,
  faChessKing,
  faChessKnight,
  faChessPawn,
  faChessQueen,
  faChessRook,
} from "@fortawesome/free-solid-svg-icons"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./index.css"
import "font-awesome/css/font-awesome.min.css"
import getFFish from "./features/games/initFfish"

library.add(fab, faChessPawn, faChessBishop, faChessKnight, faChessRook, faChessQueen, faChessKing)
getFFish()
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("app")
)

// initChessEngine()
