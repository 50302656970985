import { takeLatest } from "@redux-saga/core/effects"
import { AxiosResponse } from "axios"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { call, put } from "redux-saga/effects"
import { RespondToChallengeAction, RESPOND_TO_CHALLENGE_ACTION } from "../actions/challengeActions"
import { UserEventStatus } from "@all-good-apps/draft-chess-common"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"
import { updateUserRequestUI } from "../reducers/userRequestsUIReducer"

function* handleRespondToChallenge(action: RespondToChallengeAction) {
  const responseStatus: UserEventStatus = action.payload.status
  const gameId = action.payload.requestId

  yield put(updateUserRequestUI({ respondToChallengeLoading: true }))
  const response: AxiosResponse = yield call(authHelpers.authenticatedRequest, {
    method: "POST",
    url: `challenge/respond/${gameId}/${responseStatus}`,
  })

  const { status } = response

  if (status !== 200) {
    yield put(enqueueSnackbar("There was a problem responding to the challenge", { variant: "error" }))
  }

  yield put(updateUserRequestUI({ respondToChallengeLoading: false }))
}

export default takeLatest(RESPOND_TO_CHALLENGE_ACTION, handleRespondToChallenge)
