import { Paper, useTheme } from "@mui/material"
import DraftChessGameBoard from "./DraftChessGameBoard"
import GameMenu from "./GameMenu"

const GameCard = () => {
  const theme = useTheme()
  const boardWidth = 320

  return (
    <Paper
      variant="elevation"
      sx={(theme) => ({
        position: "relative",
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      })}
    >
      <div
        style={{
          position: "absolute",
          left: theme.spacing(1),
        }}
      >
        <GameMenu />
      </div>
      <DraftChessGameBoard boardWidth={boardWidth} grayscaleWhenOpponentsTurn={true} />
    </Paper>
  )
}

export default GameCard
