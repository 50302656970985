import { styled } from "@mui/material"
import SuggestionContextProvider from "../../suggestions/components/SuggestionContextProvider"
import SuggestionsControls from "../../suggestions/components/SuggestionsControls"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";
import ChessGameBoard from "./ChessGameBoard"
import EvaluationBar from "./EvaluationBar"
import MaterialScore from "./MaterialScore"
import OpponentAvatar from "./OpponentAvatar"
import PlayerAvatar from "./PlayerAvatar"

const CenterColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
})

const BoardContainer = styled("div")<{ active: boolean }>(({ theme, active }) => ({
  position: "relative",
  filter: active ? "none" : "grayscale(70%)",
  "&:hover": {
    filter: "none",
  },
}))

interface Props {
  boardWidth: number
  grayscaleWhenOpponentsTurn?: boolean
}

const DraftChessGameBoard = ({ boardWidth, grayscaleWhenOpponentsTurn = false }: Props) => {
  const { isPlayersTurn } = gameHooks.useGameContext()

  return (
    <CenterColumn>
      <div className="center">
        <OpponentAvatar />
        <MaterialScore player="opponent" />
      </div>
      <SuggestionContextProvider player="opponent">
        <SuggestionsControls boardWidth={boardWidth} />
      </SuggestionContextProvider>

      <BoardContainer active={!grayscaleWhenOpponentsTurn || isPlayersTurn}>
        <EvaluationBar boardWidth={boardWidth} />
        <ChessGameBoard width={boardWidth} />
      </BoardContainer>

      <SuggestionContextProvider>
        <SuggestionsControls boardWidth={boardWidth} />
      </SuggestionContextProvider>
      <div className="center">
        <PlayerAvatar />
        <MaterialScore player="player" />
      </div>
    </CenterColumn>
  )
}

export default DraftChessGameBoard
