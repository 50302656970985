import Module from "ffish-es6"

let ffish: any

const getFFish = async (): Promise<any> => {
  if (ffish) {
    return ffish
  }

  ffish = await new Module({
    locateFile: () => {
      return "/ffish.wasm"
    },
  })

  console.log(`initialized ffish`)
  return ffish
}

export default getFFish
