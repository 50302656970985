import { get, ref } from "firebase/database"
import { put, takeEvery } from "redux-saga/effects"
import { PublicProfile } from "@all-good-apps/draft-chess-common"
import { database } from "../../../firebase/admin"
import profileActions from "../actions/profileActions"

export const FETCH_PUBLIC_PROFILE_ACTION = `profile/FETCH_PUBLIC_PROFILE_ACTION`

export const fetchPublicProfiles = (ids: string[]) => ({
  type: FETCH_PUBLIC_PROFILE_ACTION,
  payload: {
    ids,
  },
})

export type FetchPublicProfilesAction = ReturnType<typeof fetchPublicProfiles>

function* handleFetchPublicProfileSaga(action: FetchPublicProfilesAction): any {
  const { ids = [] } = action.payload

  const users: PublicProfile[] = []

  for (const id of ids) {
    const publicProfileRef = ref(database, "publicProfiles/" + id)

    const snapshot = yield get(publicProfileRef)
    const friendData: PublicProfile = snapshot.val()

    users.push(friendData)
  }

  yield put(profileActions.updateFriends(users))
}

export default takeEvery(FETCH_PUBLIC_PROFILE_ACTION, handleFetchPublicProfileSaga)
