import { LoadingButton } from "@mui/lab";
import { ButtonGroup, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UserEventStatus } from "@all-good-apps/draft-chess-common";
import { gameHooks } from "@all-good-apps/draft-chess-client-api";
import NewOpponentButton from "../../matchmaking/components/NewOpponentButton";
import rematchRequestActions from "../actions/rematchRequestActions";
import { selectUserRequestsUI } from "../reducers/userRequestsUIReducer";
import rematchRequestSelector from "../selectors/rematchRequestSelector";
import { RootState } from "../../../store";

const ACCEPTED: UserEventStatus = "accepted";
const DISMISSED: UserEventStatus = "dismissed";

const RematchRequestPanel = () => {
  const { gameId, playerUid, opponentUid, opponentDisplayName, gameConfig } =
    gameHooks.useGameContext();
  const dispatch = useDispatch();

  const handleSendRematchRequest = () => {
    dispatch(rematchRequestActions.sendRematchRequest(opponentUid, gameId));
  };

  const rematchRequest = useSelector((state: RootState) =>
    rematchRequestSelector.rematchRequestById(state, gameId)
  );
  const { rematchLoading, responedToRematchLoading } =
    useSelector(selectUserRequestsUI);

  let rematchPanel = <div />;
  if (
    !rematchRequest ||
    (rematchRequest.status === "issued" && rematchRequest.from === playerUid)
  ) {
    rematchPanel = (
      <>
        <NewOpponentButton />
        <LoadingButton
          loading={rematchLoading || rematchRequest?.status === "issued"}
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSendRematchRequest}
          loadingIndicator={<CircularProgress size={14} />}
        >
          Rematch?
        </LoadingButton>
      </>
    );
  } else if (rematchRequest.status === "issued") {
    rematchPanel = (
      <div
        style={{
          flex: 1,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>{`${opponentDisplayName} requests a rematch.`}</Typography>
        <ButtonGroup>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="small"
            onClick={() =>
              dispatch(
                rematchRequestActions.respondToRematchRequest(gameId, ACCEPTED)
              )
            }
            loading={responedToRematchLoading}
          >
            Accept
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="secondary"
            size="small"
            onClick={() =>
              dispatch(
                rematchRequestActions.respondToRematchRequest(gameId, DISMISSED)
              )
            }
            loading={responedToRematchLoading}
          >
            Decline
          </LoadingButton>
        </ButtonGroup>
      </div>
    );
  } else if (rematchRequest.status === "accepted") {
    rematchPanel = <Typography variant="h5">Rematch Accepted</Typography>;
  }

  return rematchPanel;
};

export default RematchRequestPanel;
