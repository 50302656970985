import { Badge, ButtonBase, Chip, Stack, styled, Typography } from "@mui/material"
import { FC } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router"
import { gameUISelectors } from "@all-good-apps/draft-chess-client-api"
import { navigate } from "../../history/historyReducer"
import UserInfo from "../../profile/components/UserInfo"
import TopRightMenu from "./TopRightMenu"

const RootDiv = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100vh",
  width: "100vw",
  flexDirection: "row",
  justifyContent: "space-between",
}))

const TitleDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}))

const SideMenuLeft = styled("div")(({ theme }) => ({
  width: 170,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}))

const SideMenuRight = styled("div")(({ theme }) => ({
  width: 170,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-end",
}))

const MenuItemButton = (props: any) => (
  <ButtonBase
    {...props}
    sx={
      {
        // marginLeft: (theme) => theme.spacing(2),
      }
    }
  />
)

const ContentDiv = styled("div")(({ theme }) => ({
  flex: 1,
  height: "100%",
}))

const DesktopTemplatePage: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { pathname } = useLocation()

  const dispatch = useDispatch()
  const numberOfMoves = useSelector(gameUISelectors.selectNumberOfMovesToPlay)
  return (
    <RootDiv>
      <SideMenuLeft>
        <TitleDiv>
          <img
            src="/Logo.png"
            alt="logo"
            style={{
              width: 195,
              height: 30,
              objectFit: "cover",
              marginTop: 14,
              marginBottom: 8,
            }}
          />
        </TitleDiv>
        <Stack spacing={3} justifyContent={"flex-start"} alignItems={"flex-start"} paddingLeft={2}>
          <Stack flexDirection={"row"}>
            <ButtonBase onClick={() => dispatch(navigate("/settings"))}>
              <UserInfo />
            </ButtonBase>
          </Stack>
          <Stack justifyContent={"flex-start"} alignItems={"flex-start"}>
            <MenuItemButton onClick={() => dispatch(navigate("/play"))}>
              <Typography color={pathname === "/play" ? "primary" : "textPrimary"} variant="button">
                Play
              </Typography>
            </MenuItemButton>
            <MenuItemButton onClick={() => dispatch(navigate("/games"))}>
              <Typography color={pathname === "/games" ? "primary" : "textPrimary"} variant="button">
                Games
                {numberOfMoves > 0 && (
                  <Chip label={numberOfMoves} color="primary" size="small" sx={{ marginLeft: 1 }} />
                )}
              </Typography>
            </MenuItemButton>
          </Stack>
        </Stack>
      </SideMenuLeft>
      <ContentDiv>{children}</ContentDiv>
      <SideMenuRight>
        <TopRightMenu />
      </SideMenuRight>
    </RootDiv>
  )
}

export default DesktopTemplatePage
