import { EmojiEvents } from "@mui/icons-material"
import PersonIcon from "@mui/icons-material/Person"
import { styled, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { PublicProfile, User } from "@all-good-apps/draft-chess-common"
import { RootState } from "../../../store"
import profileSelector from "../selectors/profileSelector"

interface Props {
  friendUid: string
}

const RootDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  paddingTop: theme.spacing(1),
}))

const InfoDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  flex: 1,
}))

const ChallengeDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  textDecoration: "underline",
  cursor: "pointer",
  alignItems: "center",
  flex: 1,
}))

const FriendListItem = ({ friendUid }: Props) => {
  const friendInfo: PublicProfile | undefined = useSelector((state: RootState) =>
    profileSelector.friendInfoById(state, friendUid)
  )

  const displayName = friendInfo?.displayName
  const currentRating = friendInfo?.rating

  return (
    <RootDiv>
      <InfoDiv>
        <PersonIcon
          sx={{
            marginRight: (theme) => theme.spacing(1),
          }}
        />
        <Typography>{displayName}</Typography>
      </InfoDiv>
      <Typography
        sx={{
          paddingLeft: (theme) => theme.spacing(0.5),
          paddingRight: (theme) => theme.spacing(0.5),
          flex: 1,
        }}
      >
        ({currentRating})
      </Typography>
      <ChallengeDiv>
        <EmojiEvents color="primary" />
        {/* <Typography variant="button" onClick={() => dispatch(challengeActions.sendChallenge(friendUid))}>
          Challenge
        </Typography> */}
      </ChallengeDiv>
    </RootDiv>
  )
}

export default FriendListItem
