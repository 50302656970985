import { RematchRequest, UserEventStatus } from "@all-good-apps/draft-chess-common"
import { RootState } from "../../../store"

const rematchRequestById = (state: RootState, rematchId: string): RematchRequest => {
  return state.userRequests.rematchRequestsById.byId[rematchId]
}

const rematchRequestStatus = (state: RootState, rematchId: string): UserEventStatus | undefined => {
  return rematchRequestById(state, rematchId)?.status
}

export default {
  rematchRequestById,
  rematchRequestStatus,
}
