import { LoadingButton, TabPanel } from "@mui/lab"
import {
  Card,
  CardContent,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardActions,
} from "@mui/material"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { GameVariant, PublicProfile } from "@all-good-apps/draft-chess-common"
import profileSelector from "../../profile/selectors/profileSelector"
import { selectChallengeLoading } from "../../userRequests/reducers/userRequestsUIReducer"
import GameVariantSelect from "./GameVariantSelect"
import { PLAY_WITH_A_FRIEND_TAB } from "./PlayPage"
import TimeControlCard from "./TimeControlCard"
import TimeControlSelector from "./TimeControlSelector"
import useCreateChallengeRequest from "./useCreateChallengeRequest"

const PlayWithAFriendTab = () => {
  const [value, setValue] = useState()
  const [selectedFriend, setSelectedFriend] = useState<PublicProfile | undefined>()
  const [selectedGameVariant, setSelectGameVariant] = useState<GameVariant>("3check")
  const sendChallengeRequest = useCreateChallengeRequest(value, selectedGameVariant, selectedFriend?.uid)
  const challengeLoading = useSelector(selectChallengeLoading)

  const friendInfos = useSelector(profileSelector.friendInfos)
  return (
    <TabPanel sx={{ padding: 0, width: "100%" }} value={PLAY_WITH_A_FRIEND_TAB}>
      <Card>
        <CardContent>
          <Stack direction={"column"} justifyContent="center" alignItems={"center"} spacing={4}>
            <TimeControlSelector value={value} onTimeControlSelected={setValue} />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Game Variant</InputLabel>
              <GameVariantSelect
                selectedGameVariant={selectedGameVariant}
                setSelectGameVariant={setSelectGameVariant}
              />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Friend</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Friend"
                value={selectedFriend}
                onChange={(event) => {
                  setSelectedFriend(friendInfos.find((f) => f.uid === event.target.value))
                }}
              >
                {friendInfos.map((friendInfo) => (
                  <MenuItem value={friendInfo.uid}>{friendInfo.displayName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </CardContent>
        <CardActions>
          <LoadingButton
            onClick={sendChallengeRequest}
            variant="contained"
            color="primary"
            disabled={!(value && selectedFriend)}
            loading={challengeLoading}
            fullWidth
          >
            Play
          </LoadingButton>
        </CardActions>
      </Card>
    </TabPanel>
  )
}

export default PlayWithAFriendTab
