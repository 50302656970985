import { Stack } from "@mui/material"
import { isMobile } from "react-device-detect"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";
import useSuggestionContext from "../hooks/useSuggestionContext"
import RefreshSuggestionsButton from "./RefreshSuggestionsButton"
import SuggestionButtonMobile from "./SuggestionButton.mobile"
import SuggestionButton from "./SuggestionButton"
import TimeControlInfo from "../../games/components/TimeControlInfo"

interface Props {
  boardWidth: number
}

const SuggestionsControls = ({ boardWidth }: Props) => {
  const { suggestions, player, active } = useSuggestionContext()
  const { remainingSuggestions } = gameHooks.useGameContext()

  return (
    <div
      style={{
        width: "100%",
        height: boardWidth / 4.5,
        display: "flex",
        justifyContent: "center",
        marginTop: boardWidth / 16,
        marginBottom: boardWidth / 16,
      }}
    >
      <div className="center" style={{ flex: 1, height: "100%" }}>
        <TimeControlInfo player={player} />
      </div>
      <Stack spacing={2} direction="row" alignItems={"center"} justifyContent="center">
        {suggestions?.map((s, index) =>
          isMobile ? (
            <SuggestionButtonMobile suggestion={s} boardWidth={boardWidth} />
          ) : (
            <SuggestionButton key={`suggestionbutton${index}`} suggestion={s} boardWidth={boardWidth} />
          )
        )}
      </Stack>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {active && <RefreshSuggestionsButton boardWidth={boardWidth} />}
      </div>
    </div>
  )
}

export default SuggestionsControls
