import { makeStyles, Paper, styled } from "@mui/material"
import NotificationsTitle from "../atoms/titles/NotificationsTitle"
import EnableNotificationsForm from "../molecules/forms/EnableNotificationsForm"

type Props = {}

const RootDiv = styled("div")(({ theme }) => ({
  margin: theme.spacing(1),
  paddingTop: theme.spacing(2),
}))

const BoxDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
}))

const NotificationsSection = (props: Props) => {
  return (
    <RootDiv>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BoxDiv>
          <NotificationsTitle />
        </BoxDiv>
        <BoxDiv>
          <EnableNotificationsForm />
        </BoxDiv>
      </Paper>
    </RootDiv>
  )
}

export default NotificationsSection
