import { FC } from "react"
import { isMobile } from "react-device-detect"
import DesktopTemplatePage from "./DesktopTemplatePage"
import MobileTemplatePage from "./MobileTemplatePage"

interface Props {
  onGamePage?: boolean
  children: React.ReactNode
}

const TemplatePage: FC<Props> = ({ onGamePage, children }) => {
  return isMobile ? (
    <MobileTemplatePage onGamePage={onGamePage}>{children}</MobileTemplatePage>
  ) : (
    <DesktopTemplatePage>{children}</DesktopTemplatePage>
  )
}

export default TemplatePage
