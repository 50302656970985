import { UserRequest } from "@all-good-apps/draft-chess-common"
import { RootState } from "../../../store/index"

const friendRequestsById = (state: RootState, requestId: string): UserRequest => {
  return state.userRequests.friendRequestsById.byId[requestId]
}

const friendRequestIds = (state: RootState): string[] => {
  return state.userRequests.friendRequestsById.ids
}

export default {
  friendRequestsById,
  friendRequestIds,
}
