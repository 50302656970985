import { takeLatest } from "redux-saga/effects"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { call } from "redux-saga/effects"
import { SendDrawRequestAction, SEND_DRAW_REQUEST_ACTION } from "../actions/drawRequestActions"

function* handleSendDrawRequest(action: SendDrawRequestAction) {
  const to = action.payload.uid as string
  const gameId = action.payload.gameId

  yield call(authHelpers.authenticatedRequest, {
    method: "POST",
    url: `draw/issue/${to}/${gameId}`,
  })
}

export default takeLatest(SEND_DRAW_REQUEST_ACTION, handleSendDrawRequest)
