import { Stack, Typography } from "@mui/material"
import TimeControlCard from "./TimeControlCard"

type Props = {
  value?: string
  onTimeControlSelected: Function
}

const TimeControlSelector = ({ value, onTimeControlSelected }: Props) => {
  return (
    <Stack spacing={2}>
      <Typography variant="caption">Turn time</Typography>
      <Stack direction={"row"} spacing={2}>
        <TimeControlCard value="1m+10s" onClick={onTimeControlSelected} selectedValue={value} />
        <TimeControlCard value="3d" onClick={onTimeControlSelected} selectedValue={value} />
      </Stack>
    </Stack>
  )
}

export default TimeControlSelector
