import { AxiosResponse } from "axios"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"
import { updateInviteState } from "../reducers/invitesReducer"
import { put, takeLatest } from "redux-saga/effects"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { GameConfig, Invite } from "@all-good-apps/draft-chess-common"
import { updateInviteUIState } from "../reducers/inviteUIReducer"
import history from "../../history/history"
import { navigate } from "../../history/historyReducer"

export const CREATE_INVITE = `invite/CREATE_INVITE`
export const createInvite = (gameConfig: GameConfig) => ({
  type: CREATE_INVITE,
  payload: gameConfig,
})
export type CreateInviteAction = ReturnType<typeof createInvite>

function* handleCreateInviteSaga(action: CreateInviteAction): any {
  const gameConfig = action.payload

  yield put(
    updateInviteUIState({
      creatingInvite: true,
    })
  )
  const response = yield authHelpers.authenticatedRequest({
    url: "invite",
    method: "PUT",
    data: gameConfig,
  })
  yield put(
    updateInviteUIState({
      creatingInvite: false,
    })
  )

  const { status, data } = response as AxiosResponse<Invite>

  if (status === 200) {
    yield put(updateInviteState(data))
    yield put(navigate(`/newInvite/${data.inviteId}`))
  } else {
    yield enqueueSnackbar("Could not create an invite", { variant: "error" })
  }
}

export default takeLatest(CREATE_INVITE, handleCreateInviteSaga)
