import { UpdatePrivateUserInfoAction, UPDATE_PRIVATE_USER_INFO_ACTION } from "../actions/profileActions"
import { Reducer } from "redux"
import { PrivateProfile } from "@all-good-apps/draft-chess-common"

export interface PrivateUserByIdReducerState {
  userInfoPrivate: PrivateProfile
}

const initialState: PrivateUserByIdReducerState = {
  userInfoPrivate: {
    email: "",
  }
}

const privateProfileReducer: Reducer<PrivateUserByIdReducerState, UpdatePrivateUserInfoAction> = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRIVATE_USER_INFO_ACTION:
      const { profile } = action.payload

      return {
        ...state,
        userInfoPrivate: profile,
      }
    default:
      return state
  }
}

export default privateProfileReducer
