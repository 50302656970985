import { Move } from "chess.js"
import { put, takeLatest } from "redux-saga/effects"
import { updateSuggestionsState } from "../reducers/suggestionsByGameIdReducer"
import suggestionRefs from "./suggestionRefs"
import { persistHighlitedMove } from "./utils"

export const HIGHLIGHT_MOVE_ACTION = `suggestions/HIGHLIGHT_MOVE_ACTION`
export const highlightMove = (gameId: string, move: Move) => ({
  type: HIGHLIGHT_MOVE_ACTION,
  payload: {
    gameId,
    move,
  },
})
export type HighlightMoveAction = ReturnType<typeof highlightMove>

function* handleHighlightMove(action: HighlightMoveAction) {
  const { gameId, move } = action.payload

  yield put(
    updateSuggestionsState(gameId, {
      highlightedMove: move,
    })
  )

  yield persistHighlitedMove(gameId, move)
}

export default takeLatest(HIGHLIGHT_MOVE_ACTION, handleHighlightMove)
