import { createTheme } from "@mui/material"

export const boardColors = {
  higlightedSuggestion: "rgb(241, 158, 48, .8)",
  suggestion: "rgba(63, 127, 191, 0.6)",
  lightSquare: "#f0d9b5",
  darkSquare: "#b58863",
  lastMovePlayer: "rgba(101, 88, 245, .3)",
  lastMoveOpponent: "rgba(101, 88, 245, .3)",
  suggestionInCheck: "rgba(255, 0, 0, .4)",
  inCheck: "rgba(255, 0, 0, .6)",
}

// export const boardColors = {
//   higlightedSuggestion: "rgb(241, 158, 48, .8)",
//   suggestion: "rgba(63, 127, 191, 0.6)",
//   lightSquare: "#f0d9b5",
//   darkSquare: "#b58863",
//   lastMovePlayer: "rgba(101, 88, 245, .3)",
//   lastMoveOpponent: "rgba(101, 88, 245, .3)",
// }

// rgba(101, 88, 245, .3)
//"#5663F7" : "none", // #85a94e
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#F7C325",
      light: "#ffff",
      dark: "#ffc300",
    },
    secondary: {
      main: "rgb(125 135 150)",
      dark: "#001d3d",
      light: "#a099f1",
    },
    text: {
      primary: "#ffff",
      secondary: "#bababa",
      disabled: "#ffff",
    },
    error: {
      main: "#ff3d00",
    },
    warning: {
      main: "#ffab00",
    },
    info: {
      main: "#01579b",
    },
    background: {
      default: "#201f1e",
      paper: "#302e2c",
    },
  },
  typography: {
    // fontFamily: "Georgia",
    fontFamily: "Helvetica",
    button: {
      textTransform: "capitalize",
      fontWeight: 600,
    },
    body2: {
      fontWeight: 800,
      color: "lightgray",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          outline: "none",
          outlineColor: "transparent",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          outline: "none",
          "& focus": {
            outline: "none",
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: "textPrimary",
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 50,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: "1px solid #e2e2e1",
          overflow: "hidden",
          borderRadius: 4,
          backgroundColor: "#2b2b2b",
        },
      },
    },
  },
})

export default theme
