import { values } from "lodash-es"
import { useSnackbar } from "notistack"
import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { removeSnackbar, selectSnackbarsByKey } from "../snackbarReducer"

let displayed: string[] = []

const SnackbarManager = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const snackbarsByKey = useSelector(selectSnackbarsByKey)
  const snackbars = useMemo(() => values(snackbarsByKey), [snackbarsByKey])

  const storeDisplayed = (key: string) => {
    displayed = [...displayed, key]
  }

  const removeDisplayed = (key: string) => {
    displayed = [...displayed.filter((k) => key !== k)]
  }

  const dispatch = useDispatch()

  useEffect(() => {
    snackbars.forEach((snackbar) => {
      const { message, options, key, dismissed } = snackbar

      if (dismissed) {
        closeSnackbar(key)
      }

      if (displayed.includes(key)) {
        return
      }

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options?.onClose) {
            options.onClose(event, reason, myKey)
          }
        },
        onExited: (_, myKey) => {
          dispatch(removeSnackbar(myKey as string))
          removeDisplayed(myKey as string)
        },
      })

      storeDisplayed(key)
    })
  }, [snackbars, enqueueSnackbar, closeSnackbar, dispatch])

  return null
}

export default SnackbarManager
