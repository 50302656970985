import { BottomNavigation, BottomNavigationAction } from "@mui/material"
import { useDispatch } from "react-redux"
import { navigate } from "../../history/historyReducer"

import { menuItems } from "./menuItems"

const MobileMenu = () => {
  const dispatch = useDispatch()
  return (
    <BottomNavigation
      showLabels
      sx={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 1000000,
      }}
    >
      {menuItems.map((menuItem) => (
        <BottomNavigationAction
          sx={{
            color: (theme) => theme.palette.text.primary,
          }}
          color={"primary"}
          label={menuItem.text === menuItem.text}
          onClick={() => dispatch(navigate(menuItem.path))}
          icon={menuItem.icon}
        />
      ))}
    </BottomNavigation>
  )
}

export default MobileMenu
