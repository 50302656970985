import { authHelpers } from "@all-good-apps/draft-chess-client-api";
import { LeaderboardItem } from "@all-good-apps/draft-chess-common";
import { put, takeLatest } from "@redux-saga/core/effects";
import { AxiosResponse } from "axios";
import { call } from "redux-saga/effects";
import leaderboardActions, {
  GET_LEADERBOARD_ACTION,
} from "../actions/leaderboardActions";

function* handleGetLeaderBoard() {
  yield authHelpers.waitForLogIn();
  try {
    const response: AxiosResponse<LeaderboardItem[]> = yield call(
      authHelpers.authenticatedRequest,
      {
        method: "GET",
        url: `leaderboard`,
      }
    );

    const { status, data } = response;

    if (status === 200) {
      yield put(leaderboardActions.updateLeaderboard(data));
    }
  } catch (e) {
    console.log(e);
  }
}

export default takeLatest(GET_LEADERBOARD_ACTION, handleGetLeaderBoard);
