import { combineReducers } from "redux"
import friendRequestsByIdReducer from "./friendRequestsByIdReducer"
import drawRequestsByIdReducer from "./drawRequestsByIdReducer"
import rematcheRequestsByIdReducer from "./rematchRequestsByIdReducer"
import userRequestsUIReducer from "./userRequestsUIReducer"

export default combineReducers({
  friendRequestsById: friendRequestsByIdReducer,
  drawRequestsById: drawRequestsByIdReducer,
  rematchRequestsById: rematcheRequestsByIdReducer,
  userRequestsUI: userRequestsUIReducer,
})
