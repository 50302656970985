import { styled } from "@mui/material"
import { isMobile } from "react-device-detect"
import { useSelector } from "react-redux"
import TemplatePage from "../../common/components/TemplatePage"
import GameCard from "./GameCard"
import { gameRecordSelectors } from "@all-good-apps/draft-chess-client-api"
import { GameContextProvider } from "@all-good-apps/draft-chess-client-api"

const GamesContainerDiv = styled("div")(({ theme }) => ({
  maxHeight: "100%",
  // maxHeight: "100vh",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
  overflowY: "scroll",
  paddingTop: !isMobile ? theme.spacing(6) : 0,
  paddingBottom: 100,
}))

const ItemDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(isMobile ? 0 : 2),
  paddingRight: theme.spacing(isMobile ? 0 : 2),
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
}))

const GamesSection = styled("div")(({ theme }) => ({
  maxWidth: 1300,
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
}))

const GamesPage = () => {
  const gameIds = useSelector(gameRecordSelectors.gameIds)

  return (
    <TemplatePage>
      <GamesContainerDiv>
        <GamesSection>
          {gameIds.map((gameId) => (
            <ItemDiv key={`gamecard.${gameId}`}>
              <GameContextProvider gameId={gameId}>
                <GameCard />
              </GameContextProvider>
            </ItemDiv>
          ))}
        </GamesSection>
      </GamesContainerDiv>
    </TemplatePage>
  )
}

export default GamesPage
