import { Move } from "chess.js"
import { remove, set } from "firebase/database"
import suggestionRefs from "./suggestionRefs"

export function* persistHighlitedMove(gameId: string, move?: Move) {
  const ref = suggestionRefs.highlightedMove(gameId)
  if (move) {
    yield set(ref, move)
  } else {
    yield remove(ref)
  }
}
