import { DrawRequest, UserEventStatus } from "@all-good-apps/draft-chess-common"

export const UPDATE_DRAW_REQUESTS_ACTION = "offerDraw/UPDATE_DRAW_REQUESTS_ACTION"
export const SEND_DRAW_REQUEST_ACTION = "offerDraw/SEND_DRAW_REQUEST_ACTION"
export const RESPOND_TO_DRAW_REQUEST_ACTION = "offerDraw/RESPOND_TO_DRAW_REQUEST_ACTION"

const updateDrawRequests = (drawRequests: DrawRequest[]) => ({
  type: UPDATE_DRAW_REQUESTS_ACTION,
  payload: { drawRequests },
})

const sendDrawRequest = (uid: string, gameId: string) => ({
  type: SEND_DRAW_REQUEST_ACTION,
  payload: {
    uid,
    gameId,
  },
})

const respondToDrawRequest = (requestId: string, status: UserEventStatus) => ({
  type: RESPOND_TO_DRAW_REQUEST_ACTION,
  payload: {
    requestId: requestId,
    status: status,
  },
})

export type UpdateDrawRequestsAction = ReturnType<typeof updateDrawRequests>
export type SendDrawRequestAction = ReturnType<typeof sendDrawRequest>
export type RespondToDrawRequestAction = ReturnType<typeof respondToDrawRequest>

export type DrawRequestActions = SendDrawRequestAction | RespondToDrawRequestAction

export default {
  updateDrawRequests,
  sendDrawRequest,
  respondToDrawRequest,
}
