import { get, remove } from "firebase/database"
import { put, select, takeLatest } from "redux-saga/effects"
import profileSelector from "../../profile/selectors/profileSelector"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"
import { GameConfig, PublicProfile } from "@all-good-apps/draft-chess-common"
import { updateMatchmakingUiState } from "../reducers/matchmakingUIReducer"
import { matchmakingPoolRef } from "./matchmakingRefs"

export const REMOVE_FROM_MATCHMAKING_POOL = `matchmaking/REMOVE_FROM_MATCHMAKING_POOL`

export const removeFromMatchmakingPool = (gameConfig: GameConfig) => ({
  type: REMOVE_FROM_MATCHMAKING_POOL,
  payload: {
    gameConfig,
  },
})
export type RemoveFromMatchmakingPoolAction = ReturnType<typeof removeFromMatchmakingPool>

function* handleRemoveFromMatchMakingPool(action: RemoveFromMatchmakingPoolAction): any {
  const { gameConfig } = action.payload

  const { uid }: PublicProfile = yield select(profileSelector.userInfoPublic)

  const ref = matchmakingPoolRef(gameConfig, uid)
  yield put(updateMatchmakingUiState({ addingToMatchingPool: true }))
  try {
    yield remove(ref)
    yield put(updateMatchmakingUiState({ inPool: false }))
  } catch (e) {
    console.log(e)
    yield put(enqueueSnackbar("Problem being removed from matchmaking pool", { variant: "error" }))
  } finally {
    yield put(updateMatchmakingUiState({ addingToMatchingPool: false }))
  }
}

export default takeLatest(REMOVE_FROM_MATCHMAKING_POOL, handleRemoveFromMatchMakingPool)
