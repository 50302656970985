import { styled, Typography } from "@mui/material"
import { Move } from "chess.js"
import Piece from "react-chess-pieces"
import { boardColors } from "../../common/theme"

type Props = {
  suggestion: Move
  highlighted: boolean
}

const SuggestionCardContainer = styled<any>("div")(({ theme, highlighted }) => {
  return {
    width: "100%",
    height: "100%",
    outline: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.light,
    },
    backgroundColor: highlighted ? theme.palette.primary.main : theme.palette.primary.light,
    borderRadius: "11%",
    borderWidth: 0,
    borderColor: theme.palette.background.default,
    borderStyle: "solid",
    "&:focus": {
      outlineWidth: 0,
    },
    color: "initial",
    transition: "background-color .3s ease",

    boxShadow: "0px 3px 5px rgba(0, 0, 0, .5)",
  }
})

const SuggestionCardFace = (props: Props) => {
  const { suggestion, highlighted } = props
  return (
    <SuggestionCardContainer highlighted={highlighted}>
      <div className="piece">
        <Piece piece={suggestion.color === "b" ? suggestion.piece : suggestion.piece.toUpperCase()} />
      </div>
      <Typography
        variant="button"
        style={{
          color: suggestion.san.includes("+") || suggestion.san.includes("#") ? boardColors.inCheck : "slategray", //asdasd
          fontWeight: "bold",
          fontFamily: "Verdana",
        }}
      >
        {suggestion.san}
      </Typography>
    </SuggestionCardContainer>
  )
}

export default SuggestionCardFace
