import { updateInviteUIState } from "../reducers/inviteUIReducer"
import { put, takeLatest } from "redux-saga/effects"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"

export const ACCEPT_INVITE_ACTION = `invite/ACCEPT_INVITE_ACTION`

export const acceptInvite = (inviteId: string) => ({
  type: ACCEPT_INVITE_ACTION,
  payload: {
    inviteId,
  },
})
export type AcceptInviteAction = ReturnType<typeof acceptInvite>

function* handleAcceptInviteSaga(action: AcceptInviteAction): any {
  const { inviteId } = action.payload

  yield put(
    updateInviteUIState({
      acceptingInvite: true,
    })
  )
  const response = yield authHelpers.authenticatedRequest({
    url: "invite/" + inviteId,
    method: "POST",
  })

  yield put(
    updateInviteUIState({
      acceptingInvite: false,
    })
  )

  const { status, data } = response
  if (status !== 200) {
    yield put(enqueueSnackbar(`There was a problem accepting the invite ${data.error}`, { variant: "error" }))
  }
}

export default takeLatest(ACCEPT_INVITE_ACTION, handleAcceptInviteSaga)
