import { UpdateFriendRequestsAction, UPDATE_FRIEND_REQUESTS_ACTION } from "../actions/friendRequestActions"
import { Reducer } from "redux"
import { keyBy } from "lodash-es"
import { FriendRequest } from "@all-good-apps/draft-chess-common"

export interface FriendRequestsByIdReducerState {
  ids: string[]
  byId: {
    [friendRequestId: string]: FriendRequest
  }
}

const initialState: FriendRequestsByIdReducerState = {
  ids: [],
  byId: {},
}

const friendRequestsByIdReducer: Reducer<FriendRequestsByIdReducerState, UpdateFriendRequestsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_FRIEND_REQUESTS_ACTION:
      const { requests } = action.payload

      const newById = {
        ...state.byId,
        ...keyBy(requests, "requestId"),
      }

      const newIds = Object.keys(newById)

      return {
        ...state,
        ids: newIds,
        byId: newById,
      }
    default:
      return state
  }
}

export default friendRequestsByIdReducer
