import { Typography } from "@mui/material"

type Props = {
  displayName: string | undefined
}

const DisplayNameValue = (props: Props) => {
  return <Typography variant="body1">{props.displayName}</Typography>
}

export default DisplayNameValue
