import { authSelectors } from "@all-good-apps/draft-chess-client-api"
import { CircularProgress, Fade, useTheme } from "@mui/material"
import { useSelector } from "react-redux"

const LoadingScreen = () => {
  const firebaseInitialised = useSelector(authSelectors.firebaseInitialised)
  const isLoggedIn = useSelector(authSelectors.isLoggedIn)
  const theme: any = useTheme()

  return (
    <Fade appear={false} in={!(firebaseInitialised && isLoggedIn)} timeout={800}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: theme.palette.background.paper,
          zIndex: 20000,
        }}
      >
        <img
          src="/Logo.png"
          alt="logo"
          style={{
            width: 220,
            height: 39,
            objectFit: "cover",
            marginTop: 16,
            marginBottom: 16,
          }}
        />
        <CircularProgress size={80} />
      </div>
    </Fade>
  )
}

export default LoadingScreen
