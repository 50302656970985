import { LoadingButton } from "@mui/lab"
import { Button, Card, CardActions, CardContent, CardHeader, Chip, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import TemplatePage from "../../common/components/TemplatePage"
import usePublicProfile from "../../profile/hooks/usePublicProfile"
import { selectAcceptingInvite } from "../reducers/inviteUIReducer"
import { acceptInvite } from "../sagas/handleAcceptInvite"
import { useInvite } from "./useInvite"

const InvitePage = () => {
  const { inviteId } = useParams() as { inviteId: string }
  const invite = useInvite(inviteId)
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(acceptInvite(inviteId))
  }

  const acceptingInvite = useSelector(selectAcceptingInvite)
  const { displayName } = usePublicProfile(invite.from)
  return (
    <TemplatePage>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card variant="elevation">
          <CardHeader title={`You have been invited to join a game by ${displayName}`} />
          <CardContent>
            <Stack direction={"row"} spacing={2}>
              <Chip color="primary" label={invite.gameConfig?.variant} />
              <Chip color="primary" label={invite.gameConfig?.timeControls?.readableName} />
            </Stack>
          </CardContent>
          <CardActions className="center">
            <LoadingButton loading={acceptingInvite} variant="contained" color="primary" onClick={handleClick}>
              Accept
            </LoadingButton>
          </CardActions>
        </Card>
      </div>
    </TemplatePage>
  )
}

export default InvitePage
