import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import EmailValidator from "email-validator"
import friendRequestActions from "../../../../userRequests/actions/friendRequestActions"
import { makeStyles, TextField, Button, styled } from "@mui/material"

type Props = {
  onClick: () => void | undefined
}

const SendFriendRequestFormDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}))

const SendFriendRequestForm = (props: Props) => {
  const { onClick } = props
  const dispatch = useDispatch()
  const [friendEmail, setFriendEmail] = useState("")
  const [isDisabled, setIsDisabled] = useState(true)

  const isValid = EmailValidator.validate(friendEmail)

  const handleSendFriendRequest = () => {
    dispatch(friendRequestActions.sendFriendRequest(friendEmail))
    if (onClick) {
      onClick()
    }
  }

  useEffect(() => {
    setIsDisabled(!isValid)
  })

  return (
    <SendFriendRequestFormDiv>
      <TextField
        color="primary"
        variant="outlined"
        required
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        onChange={(event) => setFriendEmail(event.currentTarget.value)}
        style={{ width: "75%" }}
        size="small"
      />
      <Button variant="contained" color="primary" onClick={handleSendFriendRequest} size="small" disabled={isDisabled}>
        Send
      </Button>
    </SendFriendRequestFormDiv>
  )
}

export default SendFriendRequestForm
