import PersonIcon from "@mui/icons-material/Person"
import { Skeleton, Typography } from "@mui/material"
import { FC } from "react"
import { useSelector } from "react-redux"
import profileSelector from "../../profile/selectors/profileSelector"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";
import FlagMenuAndButton from "./FlagMenuAndButton"

const PlayerAvatar: FC = () => {
  const playerInfo = useSelector(profileSelector.userInfoPublic)
  const { isGameDataLoading } = gameHooks.useGameContext()
  const displayName = playerInfo.displayName
  const currentRating = playerInfo.rating

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingRight: 10,
      }}
    >
      <FlagMenuAndButton />
      <div style={{ width: 10 }} />
      <Typography variant="body2">
        {!displayName ? <Skeleton variant="text" width={80} animation="wave" /> : displayName}
      </Typography>
      {/* <Typography variant="body2" color="secondary">
        &nbsp;{` (${displayName ? currentRating : 1500})`}
      </Typography> */}
    </div>
  )
}

export default PlayerAvatar
