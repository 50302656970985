import { LockOutlined } from "@mui/icons-material"
import { Avatar, Box, Button, FormControl, Grid, Link, Paper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { authActions } from "@all-good-apps/draft-chess-client-api"

const RootBox = (props: any) => (
  <Box
    {...props}
    sx={{
      position: "absolute",
      top: "30%",
      left: "50%",
      backgroundColor: "background.default",
      width: 400,
      border: "2px solid",
      marginTop: (theme) => theme.spacing(8),
      paddingBottom: (theme) => theme.spacing(2),
      transform: "translate(-50%, -50%)",
    }}
  />
)

const ContainerPaper = (props: any) => (
  <Paper
    {...props}
    sx={{ marginTop: (theme) => theme.spacing(8), display: "flex", flexDirection: "column", alignItems: "center" }}
  />
)

const CustomAvatar = (props: any) => (
  <Avatar
    {...props}
    sx={{
      margin: (theme) => theme.spacing(1),
      backgroundColor: (theme) => theme.palette.secondary.main,
    }}
  />
)

const SendPasswordForm = (props: any) => (
  <FormControl {...props} sx={{ width: "50%", marginTop: (theme) => theme.spacing(1) }} />
)

const SubmitButton = (props: any) => (
  <Button
    {...props}
    sx={{
      margin: (theme) => theme.spacing(3, 0, 2),
    }}
  />
)

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("")

  const dispatch = useDispatch()

  const handleResetPassword = () => {
    dispatch(authActions.resetPassword(email))
  }

  const handleForgotPasswordModalClose = () => {
    dispatch(
      authActions.update({
        isForgotPasswordModalActive: false,
      })
    )
  }

  return (
    <RootBox>
      <ContainerPaper>
        <CustomAvatar>
          <LockOutlined />
        </CustomAvatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <SendPasswordForm noValidate>
          <TextField
            variant="outlined"
            color="secondary"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
          <SubmitButton fullWidth variant="contained" color="primary" onClick={handleResetPassword}>
            Send Password Reset
          </SubmitButton>
        </SendPasswordForm>
      </ContainerPaper>
      <div>
        <Grid container>
          <Grid item xs>
            <Typography variant="body2" color="textPrimary" align="center">
              <Link component="button" variant="body2" onClick={handleForgotPasswordModalClose}>
                Back
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </RootBox>
  )
}

export default ForgotPasswordPage
