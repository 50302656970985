import { initializeApp } from "firebase/app"

import { firebaseConfig } from "./config"
import { connectDatabaseEmulator, getDatabase } from "firebase/database"
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import { connectAuthEmulator, getAuth } from "firebase/auth"
import { getMessaging } from "firebase/messaging"

const firebaseApp = initializeApp(firebaseConfig)

const database = getDatabase(firebaseApp)
const firestore = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp)

if (process.env.REACT_APP_USE_EMULATOR && process.env.NODE_ENV === "development") {
  connectFirestoreEmulator(firestore, "localhost", 8083)
  connectDatabaseEmulator(database, "localhost", 9001)
  connectAuthEmulator(auth, "http://localhost:9099")
}

// redirect to localhost for now
const actionCodeSettings = {
  url: "http://localhost:3000/auth",
  handleCodeInApp: false,
}

const firebaseMessaging = getMessaging(firebaseApp)

export { firebaseApp, database, firestore, auth, actionCodeSettings, firebaseMessaging }
