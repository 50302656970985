import { SnackbarProvider } from "notistack"
import SnackbarManager from "./SnackbarManager"

const SnackbarDispatcher = () => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    autoHideDuration={3000}
  >
    <SnackbarManager />
  </SnackbarProvider>
)

export default SnackbarDispatcher
