import { UpdateRematchRequestsAction, UPDATE_REMATCH_REQUESTS_ACTION } from "../actions/rematchRequestActions"
import { Reducer } from "redux"
import { keyBy } from "lodash-es"
import { RematchRequest } from "@all-good-apps/draft-chess-common"

export interface RematchesByIdReducerState {
  byId: {
    [rematchId: string]: RematchRequest
  }
}

const initialState: RematchesByIdReducerState = {
  byId: {},
}

const rematchRequestsByIdReducer: Reducer<RematchesByIdReducerState, UpdateRematchRequestsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_REMATCH_REQUESTS_ACTION:
      const { rematchRequests } = action.payload

      const newById = {
        ...state.byId,
        ...keyBy(rematchRequests, "requestId"),
      }

      return {
        ...state,
        byId: newById,
      }
    default:
      return state
  }
}

export default rematchRequestsByIdReducer
