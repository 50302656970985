import { CopyAll } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import copy from "copy-to-clipboard"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import TemplatePage from "../../common/components/TemplatePage"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"
import { selectDeletingInvite } from "../reducers/inviteUIReducer"
import { deleteInvite } from "../sagas/handleDeleteInvite"
import { useInvite } from "./useInvite"

const url = "https://www.draft-chess.com/invite/"

const NewInvitePage = () => {
  const { inviteId } = useParams() as { inviteId: string }

  const invite = useInvite(inviteId)
  const deletingInvite = useSelector(selectDeletingInvite)
  const dispatch = useDispatch()
  const handleClick = () => {
    copy(url + inviteId)
    dispatch(
      enqueueSnackbar("Invite Link Copied!", {
        variant: "success",
        autoHideDuration: 1000,
      })
    )
  }

  const handleCancel = () => {
    dispatch(deleteInvite(inviteId))
  }

  return (
    <TemplatePage>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card variant="elevation">
          <CardHeader title="Challenge a friend to a game of Draft Chess" />
          <CardContent>
            <Stack spacing={3}>
              <Stack direction={"row"} spacing={2}>
                <Chip color="primary" label={invite.gameConfig?.variant} />
                <Chip color="primary" label={invite.gameConfig?.timeControls?.readableName} />
              </Stack>
              <Stack spacing={1}>
                <Typography>To invite someone to play, give them this URL</Typography>
                <div
                  style={{
                    padding: 20,
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <TextField
                    fullWidth
                    color="primary"
                    variant="standard"
                    value={url + inviteId}
                    onClick={handleClick}
                    InputProps={{
                      endAdornment: <CopyAll color="primary" />,
                    }}
                  />
                  <IconButton color="primary" onClick={handleClick}></IconButton>
                </div>
                <Typography variant="caption">The first person to come to this link with play with you.</Typography>
              </Stack>
            </Stack>
          </CardContent>
          <CardActions>
            <LoadingButton loading={deletingInvite} onClick={handleCancel}>
              Cancel
            </LoadingButton>
          </CardActions>
        </Card>
      </div>
    </TemplatePage>
  )
}

export default NewInvitePage
