import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useEffect } from "react"
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"
import { Card, Typography } from "@mui/material"
import { LeaderboardItem } from "@all-good-apps/draft-chess-common"
import { RootState } from "../../../store"
import DesktopTemplatePage from "../../common/components/DesktopTemplatePage"
import leaderboardActions from "../actions/leaderboardActions"
import leaderboardSelector from "../selectors/leaderboardSelector"

const Leaderboard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(leaderboardActions.getLeaderboard())
  }, [])

  const leaderboard: LeaderboardItem[] = useSelector((state: RootState) => leaderboardSelector.leaderboardInfo(state))

  return (
    <DesktopTemplatePage>
      <div
        className="center"
        style={{
          paddingTop: 100,
        }}
      >
        <Card
          sx={{
            width: 400,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: isMobile ? 600 : 0,
            marginLeft: (theme) => theme.spacing(6),
            marginRight: (theme) => theme.spacing(6),
          }}
        >
          <Typography component="h1" variant="h5" align="center" color="textPrimary">
            Leaderboard
          </Typography>
          <Table
            sx={{
              paddingTop: (theme) => theme.spacing(6),
            }}
            size="small"
            aria-label="leaderboard table"
          >
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>Player</TableCell>
                <TableCell>Rating</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaderboard.map((player) => (
                <TableRow key={player.userName}>
                  <TableCell component="th" scope="row">
                    {player.userName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {player.rating}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </div>
    </DesktopTemplatePage>
  )
}

export default Leaderboard
