import { Flag } from "@mui/icons-material"
import { Menu, MenuItem } from "@mui/material"
import React from "react"
import { useDispatch } from "react-redux"
import { gamesActions } from "@all-good-apps/draft-chess-client-api"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";

const FlagMenuAndButton = () => {
  const { gameId } = gameHooks.useGameContext()
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleResign = () => {
    dispatch(gamesActions.resign(gameId))
    setAnchorEl(null)
  }

  return (
    <>
      <Flag color="primary" onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleResign}>Resign</MenuItem>
        <MenuItem onClick={handleMenuClose}>Offer draw</MenuItem>
      </Menu>
    </>
  )
}

export default FlagMenuAndButton
