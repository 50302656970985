import { RootState } from "../../../store"

interface MatchmakingUIState {
  addingToMatchingPool: boolean
  inPool: boolean
}

const initialState: MatchmakingUIState = {
  addingToMatchingPool: false,
  inPool: false,
}

export const UPDATE_MATCHMAKING_UI_STATE = `matchmaking/UPDATE_MATCHMAKING_UI_STATE`

export const updateMatchmakingUiState = (payload: Partial<MatchmakingUIState>) => ({
  type: UPDATE_MATCHMAKING_UI_STATE,
  payload,
})
export type UpdateMatchmakingUiStateAction = ReturnType<typeof updateMatchmakingUiState>

const matchmakingUIReducer = (state = initialState, action: UpdateMatchmakingUiStateAction) => {
  switch (action.type) {
    case UPDATE_MATCHMAKING_UI_STATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default matchmakingUIReducer

export const selectAddingToMatchmakingPool = (state: RootState) => state.matchmaking.ui.addingToMatchingPool
export const selectInPool = (state: RootState) => state.matchmaking.ui.inPool
