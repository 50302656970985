import { styled } from "@mui/material"
import Stack from "@mui/material/Stack"
import EmailLabel from "../../atoms/labels/EmailLabel"
import EmailValue from "../../atoms/values/EmailValue"

type Props = {
  email: string | undefined
}

const RootDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
}))

const EmailInformation = (props: Props) => {
  return (
    <RootDiv>
      <Stack
        spacing={1}
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        <EmailLabel />
      </Stack>
      <Stack
        spacing={1}
        sx={{
          display: "flex",
          flex: 1,
        }}
      >
        <EmailValue email={props.email} />
      </Stack>
    </RootDiv>
  )
}

export default EmailInformation
