import { Typography } from "@mui/material"
import { useSelector } from "react-redux"
import profileSelector from "../selectors/profileSelector"

const UserInfo = () => {
  const user = useSelector(profileSelector.userInfoPublic)
  const displayName = user.displayName
  const currentRating = user.rating

  if (!user) {
    return null
  }

  return (
    <Typography variant="h6">
      {displayName}
      <Typography
        variant="caption"
        color="secondary"
        sx={{
          marginLeft: (theme) => theme.spacing(1),
          padding: 0,
        }}
      >
        {currentRating > 0 ? currentRating : ""}
      </Typography>
    </Typography>
  )
}

export default UserInfo
