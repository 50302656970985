import { combineReducers } from "redux"
import privateProfileReducer from "./privateProfileReducer"
import publicProfileReducer from "./publicProfileReducer"
import userPublicProfileByIdReducer from "./userPublicProfileByIdReducer"

export default combineReducers({
    userPrivateProfile: privateProfileReducer,
    userPublicProfile: publicProfileReducer,
    userPublicProfileById: userPublicProfileByIdReducer,
})
