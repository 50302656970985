import { all } from "redux-saga/effects";
import handleHighlightMove from "./handleHighlightMove";
import handleRefreshSuggestions from "./handleRefreshSuggestions";
import handleUnhighlightMove from "./handleUnhighlightMove";
import listenForHighlightedMove from "./listenForHighlightedMove";
import handleDismissSuggestion from "./handleDismissSuggestion";

export default function* () {
  yield all([
    handleUnhighlightMove,
    handleRefreshSuggestions,
    handleDismissSuggestion,
    handleHighlightMove,
    listenForHighlightedMove,
  ]);
}
