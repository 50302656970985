import { put, takeLatest } from "redux-saga/effects"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"
import { updateInviteUIState } from "../reducers/inviteUIReducer"
import { navigate } from "../../history/historyReducer"

export const DELETE_INVITE = `invite/DELETE_INVITE`
export const deleteInvite = (inviteId: string) => ({
  type: DELETE_INVITE,
  payload: {
    inviteId,
  },
})

export type DeleteInviteAction = ReturnType<typeof deleteInvite>

function* handleDeleteInviteSaga(action: DeleteInviteAction): any {
  const { inviteId } = action.payload

  yield put(
    updateInviteUIState({
      deletingInvite: true,
    })
  )
  const response = yield authHelpers.authenticatedRequest({
    url: "invite/" + inviteId,
    method: "delete",
  })
  yield put(
    updateInviteUIState({
      deletingInvite: false,
    })
  )

  const { status } = response

  if (status !== 200) {
    yield enqueueSnackbar("Could not delete invite", { variant: "error" })
  } else {
    yield put(navigate("/play"))
  }
}

export default takeLatest(DELETE_INVITE, handleDeleteInviteSaga)
