import { Button, Modal } from "@mui/material"
import { useState } from "react"
import SignInWidget from "./SignInWidget"

type Props = { mode?: "signIn" | "createAccount"; fullWidth?: boolean; variant?: "contained" | "outlined" }

const SignInButton = ({ mode = "createAccount", ...rest }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button variant="contained" {...rest} onClick={() => setOpen(true)}>
        {mode === "signIn" ? "Sign In to another account" : "Create Account"}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <SignInWidget mode={mode} onClose={() => setOpen(false)} />
      </Modal>
    </>
  )
}

export default SignInButton
