import { put, select, takeLatest } from "@redux-saga/core/effects"
import { Move } from "chess.js"
import { selectHighlightedMove, updateSuggestionsState } from "../reducers/suggestionsByGameIdReducer"
import { persistHighlitedMove } from "./utils"

export const UNHIGHLIGHT_SUGGESTION_ACTION = "games/UNHIGHLIGHT_SUGGESTION_ACTION"
export const unhighlightSuggestion = (gameId: string, suggestion: Move) => ({
  type: UNHIGHLIGHT_SUGGESTION_ACTION,
  payload: {
    gameId,
    suggestion,
  },
})
export type UnhighlightSuggestionAction = ReturnType<typeof unhighlightSuggestion>

function* handleUnhighlightSuggestion(action: UnhighlightSuggestionAction) {
  const { gameId, suggestion } = action.payload
  const h: Move = yield select(selectHighlightedMove, gameId)

  if (h?.san === suggestion?.san) {
    yield put(
      updateSuggestionsState(gameId, {
        highlightedMove: undefined,
      })
    )
    yield persistHighlitedMove(gameId, undefined)
  }
}

export default takeLatest(UNHIGHLIGHT_SUGGESTION_ACTION, handleUnhighlightSuggestion)
