import { UpdateLeaderboardAction, UPDATE_LEADERBOARD_ACTION } from "../actions/leaderboardActions"
import { Reducer } from "redux"
import { LeaderboardItem } from "@all-good-apps/draft-chess-common"


export interface LeaderboardReducerState {
  leaderboard: LeaderboardItem[]
}

const initialState: LeaderboardReducerState = {
  leaderboard: [],
}

const leaderboardByIdReducer: Reducer<LeaderboardReducerState, UpdateLeaderboardAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_LEADERBOARD_ACTION:
      const { leaderboard } = action.payload

      return {
        ...state,
        leaderboard: leaderboard,
      }

    default:
      return state
  }
}

export default leaderboardByIdReducer
