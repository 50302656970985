import { firebaseReducer } from "react-redux-firebase";
import { combineReducers } from "redux";
import historyReducer from "../features/history/historyReducer";
import inviteReducer from "../features/invite/reducers/inviteReducer";
import leaderboardReducer from "../features/leaderboard/reducers/leaderboardReducer";
import matchmakingReducers from "../features/matchmaking/reducers/matchmakingReducers";
import profileReducer from "../features/profile/reducers/profileReducer";
import { snackbarReducer } from "../features/snackbar/snackbarReducer";
import suggestionsReducer from "../features/suggestions/reducers/suggestionsReducer";
import userRequestsReducer from "../features/userRequests/reducers/userRequestsReducer";
import { authReducer, gamesReducer } from "@all-good-apps/draft-chess-client-api";

export default combineReducers({
  profile: profileReducer,
  userRequests: userRequestsReducer,
  leaderboard: leaderboardReducer,
  firebase: firebaseReducer,
  snackbar: snackbarReducer,
  suggestions: suggestionsReducer,
  invites: inviteReducer,
  matchmaking: matchmakingReducers,
  history: historyReducer,

  // stuff from client api
  auth: authReducer,
  games: gamesReducer,
});
