import { makeStyles, Paper, Button, styled } from "@mui/material"
import { useState } from "react"
import FriendRequestSentLabel from "../atoms/labels/FriendRequestSentLabel"
import SendFriendRequestTitle from "../atoms/titles/SendFriendRequestTitle"
import SendFriendRequestForm from "../molecules/forms/SendFriendRequestForm"

type Props = {}

const RootDiv = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  paddingTop: theme.spacing(2),
}))

const BoxDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
}))

const BackDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
}))

const SendFriendRequestSection = (props: Props) => {
  const [expand, setExpand] = useState(false)
  const toggle = () => {
    setExpand((prev) => !prev)
  }

  return (
    <RootDiv>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BoxDiv>
          <SendFriendRequestTitle />
        </BoxDiv>

        {!expand ? (
          <BoxDiv>
            <SendFriendRequestForm onClick={toggle} />
          </BoxDiv>
        ) : (
          <BackDiv>
            <FriendRequestSentLabel />
            <Button variant="contained" color="primary" size="small" onClick={toggle}>
              Back
            </Button>
          </BackDiv>
        )}
      </Paper>
    </RootDiv>
  )
}

export default SendFriendRequestSection
