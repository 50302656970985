import { CircularProgress, styled, Typography } from "@mui/material"
import { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { playSound } from "../../sound/sagas/handlePlaySound"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";
import { gamesActions } from "@all-good-apps/draft-chess-client-api"

const RoodDiv = styled("div")(({ theme }) => ({
  alignItems: "center",
  width: 40,
  height: 40,
  position: "relative",
  margin: theme.spacing(0.5),
}))

const TimerLabel = (props: any) => (
  <Typography
    {...props}
    sx={{
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    variant="caption"
  />
)

const useCounter = (time: number, pause: boolean) => {
  const timeLeft = useMemo(() => {
    return time - Date.now()
  }, [time])

  const [currentTime, setCurrentTime] = useState(Math.floor(Math.max(0, timeLeft) / 1000))
  useEffect(() => {
    setCurrentTime(Math.floor(Math.max(0, timeLeft) / 1000))
  }, [timeLeft])

  const pauseRef = useRef(pause)
  useEffect(() => {
    pauseRef.current = pause
  }, [pause])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!pauseRef.current) {
        setCurrentTime((prevTime) => Math.max(0, prevTime - 1))
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return currentTime
}

const getTimerLabel = (currentTime: number) => {
  switch (true) {
    case currentTime <= 60:
      return `${Math.floor(currentTime)}s`
    case currentTime <= 60 * 60:
      return `${Math.floor(currentTime / 60)}m`
    case currentTime <= 60 * 60 * 24:
      return `${Math.floor(currentTime / (60 * 60))}h`
    default:
      return `${Math.floor(currentTime / (60 * 60 * 24))}d`
  }
}

const TimeControlInfo = ({ player }: { player: "player" | "opponent" }) => {
  const { gameId, timeControls, gameConfig, playerColor, turn, status, isGameDataLoading } = gameHooks.useGameContext()

  const { blackTime = 0, whiteTime = 0, lastMove = 0 } = timeControls || {}

  const color = player === "player" ? playerColor : playerColor === "w" ? "b" : "w"

  const totalTime =
    (gameConfig?.timeControls.type === "daily"
      ? gameConfig?.timeControls.moveTime
      : gameConfig?.timeControls.totalTime) / 1000
  const time = (color === "w" ? whiteTime : blackTime) + lastMove

  const currentTime = useCounter(time, color !== turn || status !== "in_progress")

  const isTimerYellow = currentTime < 15
  const isTimerRed = currentTime < 6

  const dispatch = useDispatch()

  useEffect(() => {
    if (isTimerRed && !isGameDataLoading) {
      dispatch(playSound("tick"))
    }
    if (currentTime <= 0 && !isGameDataLoading) {
      dispatch(gamesActions.gameTimeout(gameId))
    }
  }, [currentTime])

  return (
    <RoodDiv>
      <CircularProgress
        variant="determinate"
        value={Math.min((currentTime / totalTime) * 100, 100)}
        sx={{
          color: (theme) =>
            isTimerRed
              ? theme.palette.error.main
              : isTimerYellow
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
        }}
      />
      <TimerLabel>{getTimerLabel(currentTime)}</TimerLabel>
    </RoodDiv>
  )
}
export default TimeControlInfo
