import { eventChannel, EventChannel } from "redux-saga";
import { cancel, delay, fork, take } from "redux-saga/effects";
import { authHelpers } from "@all-good-apps/draft-chess-client-api";

type ActivityEvent = "blur" | "focus";

const activityChannel = (): EventChannel<ActivityEvent> => {
  return eventChannel((emit) => {
    window.onblur = () => {
      emit("blur");
    };
    window.onfocus = () => {
      emit("focus");
    };
    return () => {};
  });
};

function* callPrimeAPI() {
  while (true) {
    try {
      yield authHelpers.authenticatedRequest({ url: "/prime" });
    } catch (e) {
      console.error("There was a problem priming - " + e); // console.log(e)
    }
    yield delay(10000);
  }
}

function* primerSaga(): any {
  yield authHelpers.waitForLogIn();
  const channel: EventChannel<ActivityEvent> = yield activityChannel();
  let task = yield fork(callPrimeAPI);
  while (true) {
    const activityEvent: ActivityEvent = yield take(channel);
    if (activityEvent === "focus") {
      if (!task) {
        task = yield fork(callPrimeAPI);
      }
    } else {
      if (task) {
        yield cancel(task);
        task = null;
      }
    }
  }
}

export default primerSaga;
