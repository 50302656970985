import { RootState } from "../../../store/index"
import { PrivateProfile, PublicProfile } from "@all-good-apps/draft-chess-common"

const userInfoPrivate = (state: RootState): PrivateProfile => {
  return state.profile.userPrivateProfile.userInfoPrivate
}

const userInfoPublic = (state: RootState): PublicProfile => {
  return state.profile.userPublicProfile.userInfoPublic
}

const friendInfoById = (state: RootState, uid: string): PublicProfile => {
  return state.profile.userPublicProfileById.byId[uid]
}

const friendInfoIds = (state: RootState): string[] => {
  return state.profile.userPublicProfileById.ids
}

const friendInfos = (state: RootState): PublicProfile[] => {
  return state.profile.userPublicProfileById.ids.map((id) => state.profile.userPublicProfileById.byId[id])
}

export default {
  userInfoPrivate,
  userInfoPublic,
  friendInfoById,
  friendInfoIds,
  friendInfos,
}
