import { LeaderboardItem } from "@all-good-apps/draft-chess-common"
import { RootState } from "../../../store"

const leaderboardInfo = (state: RootState): LeaderboardItem[] => {
  return state.leaderboard.leaderboardData.leaderboard
}

export default {
  leaderboardInfo,
}
