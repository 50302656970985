import { Done } from "@mui/icons-material"
import { ListItem, Typography, Button, styled } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { FriendRequest, UserEventStatus } from "@all-good-apps/draft-chess-common"
import { RootState } from "../../../store"
import { authSelectors } from "@all-good-apps/draft-chess-client-api"
import friendRequestActions from "../actions/friendRequestActions"
import friendRequestSelector from "../selectors/friendRequestSelector"

const ACCEPTED: UserEventStatus = "accepted"
const DISMISSED: UserEventStatus = "dismissed"

interface Props {
  friendRequestId: string
}

const ListItemDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}))

const ButtonContainerDiv = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(1),
  display: "flex",
}))

const AcceptDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(1),
  flex: 1,
}))

const DismissDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "right",
  textDecoration: "underline",
  paddingLeft: theme.spacing(2),
  flex: 1,
  alignItems: "center",
}))

const FriendRequestItem = ({ friendRequestId }: Props) => {
  const friendRequest: FriendRequest | undefined = useSelector((state: RootState) =>
    friendRequestSelector.friendRequestsById(state, friendRequestId)
  )
  const uid: string = useSelector(authSelectors.uid)

  const dispatch = useDispatch()

  const { fromName, from, to, toName } = friendRequest

  if (!friendRequest || friendRequest.status !== "issued") {
    return null
  }

  const userHasIssuedRequest: boolean = from === uid

  return (
    <>
      {userHasIssuedRequest ? (
        <>
          <ListItem dense key={`request.${friendRequestId}`} >
            <ListItemDiv>
              <Typography variant="subtitle2">You have sent a friend request to {toName}.</Typography>
            </ListItemDiv>
          </ListItem>
        </>
      ) : (
        <>
          <ListItem dense key={`request.${friendRequestId}`} >
            <ListItemDiv>
              <div>
                <Typography variant="subtitle2">{fromName} wants to be your friend.</Typography>
              </div>
              <ButtonContainerDiv>
                <AcceptDiv>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(friendRequestActions.respondToFriendRequest(friendRequestId, ACCEPTED))}
                    startIcon={<Done />}
                    size="small"
                  >
                    Accept
                  </Button>
                </AcceptDiv>
                <DismissDiv>
                  <Typography
                    variant="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => dispatch(friendRequestActions.respondToFriendRequest(friendRequestId, DISMISSED))}
                  >
                    Dismiss
                  </Typography>
                </DismissDiv>
              </ButtonContainerDiv>
            </ListItemDiv>
          </ListItem >
        </>
      )}
    </>
  )

}

export default FriendRequestItem
