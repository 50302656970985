import { all } from "redux-saga/effects";
import profileSagas from "../features/profile/sagas/profileSagas";
import leaderboardSagas from "../features/leaderboard/sagas/leaderboardSagas";
import userRequestsSagas from "../features/userRequests/sagas/userRequestsSagas";
import notifcationSagas from "../features/notifications/sagas/notifcationSagas";
import primerSaga from "../features/common/sagas/primerSaga";
import suggestionSagas from "../features/suggestions/sagas/suggestionSagas";
import inviteSagas from "../features/invite/sagas/inviteSagas";
import soundSagas from "../features/sound/sagas/soundSagas";
import matchmakingSagas from "../features/matchmaking/sagas/matchmakingSagas";
import { authSagas, gamesSagas } from "@all-good-apps/draft-chess-client-api";

export default function* () {
  yield all([
    profileSagas(),
    leaderboardSagas(),
    userRequestsSagas(),
    notifcationSagas(),
    primerSaga(),
    suggestionSagas(),
    inviteSagas(),
    soundSagas(),
    matchmakingSagas(),

    // stuff from client api
    authSagas(),
    gamesSagas(),
  ]);
}
