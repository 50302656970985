import { set } from "firebase/database"
import { put, select, takeLatest } from "redux-saga/effects"
import profileSelector from "../../profile/selectors/profileSelector"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"
import { GameConfig, PublicProfile } from "@all-good-apps/draft-chess-common"
import { updateMatchmakingUiState } from "../reducers/matchmakingUIReducer"
import { matchmakingPoolRef } from "./matchmakingRefs"

export const ADD_TO_MATCHMAKING_POOL = `matchmaking/ADD_TO_MATCHMAKING_POOL`

export const addToMatchmakingPool = (gameConfig: GameConfig) => ({
  type: ADD_TO_MATCHMAKING_POOL,
  payload: {
    gameConfig,
  },
})
export type AddToMatchmakingPoolAction = ReturnType<typeof addToMatchmakingPool>

function* handleAddToMatchMakingPool(action: AddToMatchmakingPoolAction) {
  const { gameConfig } = action.payload

  const { uid, rating }: PublicProfile = yield select(profileSelector.userInfoPublic)
  const ref = matchmakingPoolRef(gameConfig, uid)

  yield put(updateMatchmakingUiState({ addingToMatchingPool: true }))
  try {
    yield set(ref, rating)
    yield put(updateMatchmakingUiState({ inPool: true }))
  } catch (e) {
    console.log(e)
    yield put(enqueueSnackbar("Failed to add to matchmaking pool", { variant: "error" }))
  } finally {
    yield put(updateMatchmakingUiState({ addingToMatchingPool: false }))
  }
}

export default takeLatest(ADD_TO_MATCHMAKING_POOL, handleAddToMatchMakingPool)
