import { takeLatest } from "@redux-saga/core/effects"
import { AxiosResponse } from "axios"
import { call, put } from "redux-saga/effects"
import friendRequestActions, {
  RespondToFriendRequestAction,
  RESPOND_TO_FRIEND_REQUEST_ACTION,
} from "../actions/friendRequestActions"
import { FriendRequest, UserEventStatus } from "@all-good-apps/draft-chess-common"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"

function* handleRespondToFriendRequest(action: RespondToFriendRequestAction) {
  const responseStatus: UserEventStatus = action.payload.status
  const requestId = action.payload.requestId

  const response: AxiosResponse<FriendRequest> = yield call(authHelpers.authenticatedRequest, {
    method: "POST",
    url: `friends/respond/${requestId}/${responseStatus}`,
  })

  const { status, data } = response

  if (status === 200) {
    yield put(friendRequestActions.updateRequests([data]))
  }
}

export default takeLatest(RESPOND_TO_FRIEND_REQUEST_ACTION, handleRespondToFriendRequest)
