import { takeLatest } from "@redux-saga/core/effects"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { call, put } from "redux-saga/effects"
import {
  SendFriendRequestAction,
  SEND_FRIEND_REQUEST_ACTION,
} from "../actions/friendRequestActions"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"

function* handleSendFriendRequest(action: SendFriendRequestAction) {
  const to = action.payload.email as string

  yield call(authHelpers.authenticatedRequest, {
    method: "POST",
    url: `friends/issueByEmail/email`,
    params: {
      email: to,
    },
  })

  yield put(enqueueSnackbar(`Friend request sent to: ${to}`, { variant: "info" }))
}

export default takeLatest(SEND_FRIEND_REQUEST_ACTION, handleSendFriendRequest)
