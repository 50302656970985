import { RematchRequest, UserEventStatus } from "@all-good-apps/draft-chess-common"

export const UPDATE_REMATCH_REQUESTS_ACTION = "rematch/UPDATE_REMATCH_REQUESTS_ACTION"
export const SEND_REMATCH_ACTION = "rematch/SEND_REMATCH_ACTION"
export const RESPOND_TO_REMATCH_ACTION = "rematch/RESPOND_TO_REMATCH_ACTION"

const updateRematchRequests = (rematchRequests: RematchRequest[]) => ({
  type: UPDATE_REMATCH_REQUESTS_ACTION,
  payload: { rematchRequests },
})

const sendRematchRequest = (uid: string, gameId: string) => ({
  type: SEND_REMATCH_ACTION,
  payload: {
    uid,
    gameId,
  },
})

const respondToRematchRequest = (requestId: string, status: UserEventStatus) => ({
  type: RESPOND_TO_REMATCH_ACTION,
  payload: {
    requestId: requestId,
    status: status,
  },
})

export type UpdateRematchRequestsAction = ReturnType<typeof updateRematchRequests>
export type SendRematchRequestAction = ReturnType<typeof sendRematchRequest>
export type RespondToRematchRequestAction = ReturnType<typeof respondToRematchRequest>

export type RematchRequestActions = SendRematchRequestAction | RespondToRematchRequestAction

export default {
  updateRematchRequests,
  sendRematchRequest,
  respondToRematchRequest,
}
