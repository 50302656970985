import { Skeleton, Typography } from "@mui/material"
import { FC } from "react"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";

const OpponentAvatar: FC = () => {
  const { opponentDisplayName, opponentEloRating, isOpponentInfoLoading } = gameHooks.useGameContext()

  return (
    <div
      style={{
        display: "flex",
        paddingRight: 10,
      }}
    >
      <Typography variant="body2">
        {isOpponentInfoLoading ? <Skeleton variant="text" width={80} animation="wave" /> : opponentDisplayName}
      </Typography>
      {/* <Typography variant="body2" color="secondary">
        &nbsp;{` (${opponentEloRating})`}
      </Typography> */}
    </div>
  )
}

export default OpponentAvatar
