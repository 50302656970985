import { all } from "redux-saga/effects"
import listenForPrivateProfile from "./listenForPrivateProfile"
import listenForPublicProfile from "./listenForPublicProfile"
import listenForOtherUsers from "./listenForOtherUsers"
import handleUpdateDisplayName from "./handleUpdateDisplayName"
import handleFetchPublicProfileSaga from "./handleFetchPublicProfile"

export default function* () {
  yield all([listenForPrivateProfile(), listenForPublicProfile(),
  listenForOtherUsers(), handleUpdateDisplayName, handleFetchPublicProfileSaga])
}
