import { ReplayRounded } from "@mui/icons-material"
import { IconButton, Typography, useTheme } from "@mui/material"
import { useDispatch } from "react-redux"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";
import { refreshSuggestions } from "../sagas/handleRefreshSuggestions"
import useSuggestionContext from "../hooks/useSuggestionContext"
import { playSound } from "../../sound/sagas/handlePlaySound"

type Props = { boardWidth: number }

const RefreshSuggestionsButton = ({ boardWidth }: Props) => {
  const { gameId, remainingSuggestions } = gameHooks.useGameContext()
  const { hasRemainingSuggestions, active, player } = useSuggestionContext()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(refreshSuggestions(gameId))
    dispatch(playSound("shuffle"))
  }

  return (
    <IconButton
      color="primary"
      sx={{
        width: (boardWidth / 4) * 0.4,
        outline: "none",
        display: "flex",

        // "&:hover": {
        //   backgroundColor: (theme) => theme.palette.primary.main,
        //   borderColor: (theme) => theme.palette.primary.light,
        // },
        borderRadius: 10000,
        borderWidth: 0,
        borderColor: (theme) => theme.palette.background.default,
        borderStyle: "solid",
        margin: (theme) => theme.spacing(1),
        "&:focus": {
          outlineWidth: 0,
        },
        flexDirection: "column",
      }}
      onClick={handleClick}
      disabled={!hasRemainingSuggestions || player === "opponent"}
    >
      <ReplayRounded fontSize="large" />
      <Typography
        variant="caption"
        sx={(theme) => ({ color: hasRemainingSuggestions ? "" : theme.palette.text.secondary })}
      >
        {remainingSuggestions.length}
      </Typography>
    </IconButton>
  )
}

export default RefreshSuggestionsButton
