import { Stack } from "@mui/material"
import { useSelector } from "react-redux"
import SignInButton from "../../auth/components/SignInButton"
import { authSelectors } from "@all-good-apps/draft-chess-client-api"
import FriendListMenu from "../../profile/components/FriendListMenu"
import NotificationMenu from "./NotificationMenu"

type Props = {}

const TopRightMenu = (props: Props) => {
  const isAnonymous = useSelector(authSelectors.isAnonymous)
  return (
    <Stack flex={0} spacing={1} padding={2} direction="row">
      {isAnonymous ? (
        <SignInButton mode="createAccount" />
      ) : (
        <>
          <FriendListMenu />
          <NotificationMenu />
        </>
      )}
    </Stack>
  )
}

export default TopRightMenu
