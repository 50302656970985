import { ChallengeRequest, GameConfig, UserEventStatus } from "@all-good-apps/draft-chess-common"
import { ChallengeRequestState } from "../types/challengeTypes"

export const UPDATE_CHALLENGE_STATE_ACTION = "challenge/UPDATE_CHALLENGE_STATE_ACTION"
export const UPDATE_CHALLENGE_REQUESTS_ACTION = "challenge/UPDATE_CHALLENGE_REQUESTS_ACTION"
export const SEND_CHALLENGE_ACTION = "challenge/SEND_CHALLENGE_ACTION"
export const RESPOND_TO_CHALLENGE_ACTION = "challenge/RESPOND_TO_CHALLENGE_ACTION"

const updateChallenges = (challenges: ChallengeRequest[]) => ({
  type: UPDATE_CHALLENGE_REQUESTS_ACTION,
  payload: { challenges },
})

const updateChallengeState = (payload: Partial<ChallengeRequestState>) => ({
  type: UPDATE_CHALLENGE_STATE_ACTION,
  payload,
})

const sendChallenge = (uid: string, gameConfig: GameConfig) => ({
  type: SEND_CHALLENGE_ACTION,
  payload: {
    uid,
    gameConfig,
  },
})

const respondToChallenge = (requestId: string, status: UserEventStatus) => ({
  type: RESPOND_TO_CHALLENGE_ACTION,
  payload: {
    requestId: requestId,
    status: status,
  },
})

export type UpdateChallengeStateAction = ReturnType<typeof updateChallengeState>
export type UpdateChallengeRequestsAction = ReturnType<typeof updateChallenges>
export type SendChallengeAction = ReturnType<typeof sendChallenge>
export type RespondToChallengeAction = ReturnType<typeof respondToChallenge>

export type ChallengeActions = UpdateChallengeStateAction | SendChallengeAction | RespondToChallengeAction

export default {
  updateChallenges,
  updateChallengeState,
  sendChallenge,
  respondToChallenge,
}
