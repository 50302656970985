import { styled, Switch } from "@mui/material"
import EnableNotificationsLabel from "../../atoms/labels/EnableNotificationsLabel"

type Props = {}

const NotificationsFormDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}))

const EnableNotificationsForm = (props: Props) => {
  return (
    <NotificationsFormDiv>
      <EnableNotificationsLabel />
      <Switch name="loading" color="primary" />
    </NotificationsFormDiv>
  )
}

export default EnableNotificationsForm
