import { RootState } from "../../store"
import history from "./history"

interface HistoryState {
  location: string
}
const initialState: HistoryState = { location: "/" }

export const NAVIGATE = `history/NAVIGATE`

export const navigate = (location: string) => ({
  type: NAVIGATE,
  payload: { location },
})
export type NavigateAction = ReturnType<typeof navigate>

const historyReducer = (state = initialState, action: NavigateAction) => {
  switch (action.type) {
    case NAVIGATE:
      history.push(action.payload.location)
      return {
        location: action.payload.location,
      }
    default:
      return state
  }
}

export const selectLocation = (state: RootState) => state.history.location

export default historyReducer
