import { AnyAction, Reducer } from "redux"
import { OptionsObject } from "notistack"
import { omit } from "lodash-es"
import { RootState } from "../../store"

// Types
//
export interface DCSnackbar {
  message: string
  options?: OptionsObject
  dismissed: boolean
  key: string
}

export interface SnackbarState {
  snackbarsByKey: {
    [key: string]: DCSnackbar
  }
}

// Actions
//
export const ENQUEUE_SNACKBAR = `snackbar/ENQUEUE_SNACKBAR`
export const enqueueSnackbar = (message: string, options?: OptionsObject) => ({
  type: ENQUEUE_SNACKBAR,
  payload: {
    message,
    options,
  },
})
export type EnqueueSnackbarAction = ReturnType<typeof enqueueSnackbar>

export const REMOVE_SNACKBAR = `snackbar/REMOCE_SNACKBAR`
export const removeSnackbar = (key: string) => ({
  type: REMOVE_SNACKBAR,
  payload: { key },
})
export type RemoveSnackbarAction = ReturnType<typeof removeSnackbar>

export const DISMISS_SNACKBAR = `snackbar/DISMISS_SNACKBAR`
const dismissSnackbar = (key: string) => ({
  type: DISMISS_SNACKBAR,
  payload: {
    key,
  },
})
export type DismissSnackbarAction = ReturnType<typeof dismissSnackbar>

// reducer
const initialState: SnackbarState = { snackbarsByKey: {} }
export const snackbarReducer: Reducer<SnackbarState, AnyAction> = (state = initialState, action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      const { message, options } = action.payload

      const newKey = `${Date.now()}.${Math.random()}`

      const newSnackbar: DCSnackbar = {
        message,
        options,
        key: newKey,
        dismissed: false,
      }
      return {
        ...state,
        snackbarsByKey: {
          ...state.snackbarsByKey,
          [newKey]: newSnackbar,
        },
      }
    case REMOVE_SNACKBAR:
      return {
        ...state,
        snackbarsByKey: omit(state.snackbarsByKey, [action.payload.key]),
      }
    case DISMISS_SNACKBAR:
      const { key } = action.payload
      return {
        ...state,
        snackbarsByKey: {
          ...state.snackbarsByKey,
          [key]: {
            ...state.snackbarsByKey[key],
            dismissed: true,
          },
        },
      }
    default:
      return state
  }
}

// selectors
//
export const selectSnackbarsByKey = (state: RootState) => state.snackbar.snackbarsByKey
