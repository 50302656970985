import { updateUserRequestUI } from "../reducers/userRequestsUIReducer"
import { takeLatest } from "@redux-saga/core/effects"
import { AxiosResponse } from "axios"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { call, put } from "redux-saga/effects"
import rematchRequestActions, {
  RespondToRematchRequestAction,
  RESPOND_TO_REMATCH_ACTION,
} from "../actions/rematchRequestActions"
import { UserEventStatus } from "@all-good-apps/draft-chess-common"

function* handleRespondToRematchRequest(action: RespondToRematchRequestAction) {
  const responseStatus: UserEventStatus = action.payload.status
  const requestId = action.payload.requestId

  yield put(updateUserRequestUI({ responedToRematchLoading: true }))
  const response: AxiosResponse = yield call(authHelpers.authenticatedRequest, {
    method: "POST",
    url: `rematch/respond/${requestId}/${responseStatus}`,
  })
  const { status, data } = response

  if (status == 200) {
    yield put(rematchRequestActions.updateRematchRequests([data]))
  }
  yield put(updateUserRequestUI({ responedToRematchLoading: false }))
}

export default takeLatest(RESPOND_TO_REMATCH_ACTION, handleRespondToRematchRequest)
