import { LoadingButton, TabPanel } from "@mui/lab"
import { Card, CardActions, CardContent, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GameVariant } from "@all-good-apps/draft-chess-common"
import { selectCreatingInvite } from "../../invite/reducers/inviteUIReducer"
import { createInvite } from "../../invite/sagas/handleCreateInvite"
import { INVITE_TAB } from "./PlayPage"
import playScreenUtils from "./playScreenUtils"
import TimeControlSelector from "./TimeControlSelector"

const InviteTab = () => {
  const [value, setValue] = useState()
  const [selectedGameVariant, setSelectGameVariant] = useState<GameVariant>("3check")
  const dispatch = useDispatch()
  const handleCreateInviteClick = () => {
    dispatch(
      createInvite({
        variant: selectedGameVariant,
        timeControls: playScreenUtils.createTimeControlsConfig(value, selectedGameVariant),
      })
    )
  }
  const creatingInvite = useSelector(selectCreatingInvite)

  return (
    <TabPanel value={INVITE_TAB} sx={{ padding: 0, width: "100%" }}>
      <Card>
        <CardContent>
          <Stack spacing={4} alignItems={"center"}>
            <TimeControlSelector value={value} onTimeControlSelected={setValue} />
            <FormControl fullWidth>
              <InputLabel>Game Variant</InputLabel>
              <Select
                label="Game Variant"
                value={selectedGameVariant}
                onChange={(event) => {
                  setSelectGameVariant(event.target.value as GameVariant)
                }}
              >
                <MenuItem value="3check">3 Check</MenuItem>
                <MenuItem value="chess">Chess</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </CardContent>
        <CardActions>
          <LoadingButton
            onClick={handleCreateInviteClick}
            variant="contained"
            color="primary"
            disabled={!value}
            loading={creatingInvite}
            fullWidth
          >
            Create Invite
          </LoadingButton>
        </CardActions>
      </Card>
    </TabPanel>
  )
}

export default InviteTab
