import { Howl } from "howler"
import { delay, put, takeEvery, takeLeading } from "redux-saga/effects"

Howler.autoUnlock = false
const sounds = {
  dog: new Howl({
    src: ["/sounds/dog.mp3"],
    preload: true,
  }),
  move: new Howl({
    src: ["/sounds/chess_move.wav"],
    preload: true,
  }),
  capture: new Howl({
    src: ["/sounds/capture.mp3"],
    preload: true,
    volume: 0.3,
  }),
  flip: new Howl({
    src: ["/sounds/card_flip.ogg"],
    preload: true,
    volume: 0.25,
  }),
  start: new Howl({
    src: ["/sounds/game_start.wav"],
    preload: true,
  }),
  check: new Howl({
    src: ["/sounds/check.wav"],
    preload: true,
    volume: 1,
  }),
  win: new Howl({
    src: ["/sounds/win.wav"],
    preload: true,
  }),
  lose: new Howl({
    src: ["/sounds/lose.wav"],
    preload: true,
  }),
  shuffle: new Howl({
    src: ["/sounds/shuffle.wav"],
    preload: true,
  }),
  tick: new Howl({
    src: ["/sounds/tick.wav"],
    preload: true,
  }),
}

//actions
export const PLAY_SOUND_ACTION = `sound/PLAY_SOUND_ACTION`
export const playSound = (sound: keyof typeof sounds, delay?: number) => ({
  type: PLAY_SOUND_ACTION,
  payload: {
    sound,
    delay,
  },
})
export type PlaySoundAction = ReturnType<typeof playSound>

//sagas

export function* handlePlaySound(action: PlaySoundAction) {
  const { sound, delay: delayTime } = action.payload
  if (delayTime) {
    yield delay(delayTime)
  }
  sounds[sound].play()
}

export const PLAY_FLIP_SOUND = `sound/PLAY_FLIP_SOUND`
export const playFlipSound = () => ({
  type: PLAY_FLIP_SOUND,
})
export type PlayFlipSoundAction = ReturnType<typeof playFlipSound>

function* handlePlayFlipSound() {
  yield put(playSound("flip"))
  yield delay(50)
}

export default [takeEvery(PLAY_SOUND_ACTION, handlePlaySound), takeLeading(PLAY_FLIP_SOUND, handlePlayFlipSound)]
