import { Typography } from "@mui/material"

type Props = {
  email: string | undefined
}

const EmailValue = (props: Props) => {
  return <Typography variant="body1">{props.email}</Typography>
}

export default EmailValue
