import React from "react"
import { MenuItem, Select } from "@mui/material"
import { GameVariant } from "@all-good-apps/draft-chess-common"

type Props = {
  selectedGameVariant: string
  setSelectGameVariant: React.Dispatch<React.SetStateAction<GameVariant>>
}

const GameVariantSelect: React.FC<Props> = ({ selectedGameVariant, setSelectGameVariant }) => {
  return (
    <Select
      label="Game Variant"
      value={selectedGameVariant}
      onChange={(event: any) => {
        setSelectGameVariant(event.target.value as GameVariant)
      }}
    >
      <MenuItem value="3check">3 Check</MenuItem>
      <MenuItem value="chess">Chess</MenuItem>
    </Select>
  )
}

export default GameVariantSelect
