import { all } from "redux-saga/effects"
import listenForUserRequests from "./listenForUserRequests"
import handleSendFriendRequest from "./handleSendFriendRequest"
import handleRespondToFriendRequest from "./handleRespondToFriendRequest"
import handleSendChallenge from "./handleSendChallenge"
import handleRespondToChallenge from "./handleRespondToChallenge"
import handleSendDrawRequest from "./handleSendDrawRequest"
import handleRespondToDrawRequest from "./handleRespondToDrawRequest"
import handleSendRematchRequest from "./handleSendRematchRequest"
import handleRespondToRematchRequest from "./handleRespondToRematchRequest"

export default function* () {
  yield all([
    handleSendChallenge,
    handleRespondToChallenge,
    listenForUserRequests(),
    handleSendFriendRequest,
    handleRespondToFriendRequest,
    handleSendDrawRequest, 
    handleRespondToDrawRequest,
    handleSendRematchRequest, 
    handleRespondToRematchRequest,
  ])
}
