import TemplatePage from "../../common/components/TemplatePage"
import YourInfoSection from "./organisms/YourInfoSection"
import SendFriendRequestSection from "./organisms/SendFriendRequestSection"
import NotificationsSection from "./organisms/NotificationsSection"
import SettingsTitle from "./atoms/titles/SettingsTitle"
import { isMobile } from "react-device-detect"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material"
import { useSelector } from "react-redux"
import { authSelectors } from "@all-good-apps/draft-chess-client-api"

const RootDiv = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}))

const TitleDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "50%",
  marginBottom: theme.spacing(1),
}))

const MobileTitleDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: theme.spacing(1),
}))

const SettingsPage = () => {
  const isAnonymous = useSelector(authSelectors.isAnonymous)
  return !isMobile ? (
    <TemplatePage>
      <RootDiv>
        <TitleDiv>
          <SettingsTitle />
        </TitleDiv>
        <Stack
          spacing={1}
          sx={{
            width: "50%",
          }}
        >
          <YourInfoSection />
          {!isAnonymous && <SendFriendRequestSection />}
          {!isAnonymous && <NotificationsSection />}
        </Stack>
      </RootDiv>
    </TemplatePage>
  ) : (
    <TemplatePage>
      <RootDiv>
        <MobileTitleDiv>
          <SettingsTitle />
        </MobileTitleDiv>
        <Stack
          spacing={1}
          sx={{
            width: "95%",
          }}
        >
          <YourInfoSection />
          <SendFriendRequestSection />
          {/* <NotificationsSection /> */}
        </Stack>
      </RootDiv>
    </TemplatePage>
  )
}

export default SettingsPage
