import { call, put, takeLatest } from "redux-saga/effects"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"
import { playSound } from "../../sound/sagas/handlePlaySound"
import { UpdateDisplayNameAction, UPDATE_DISPLAY_NAME_ACTION } from "../actions/profileActions"

function* handleUpdateDisplayName(action: UpdateDisplayNameAction) {
  const { displayName } = action.payload

  yield call(authHelpers.authenticatedRequest, {
    method: "POST",
    url: `user/updateDisplayName/${displayName}`,
  })

  yield put(playSound("dog"))
  yield put(enqueueSnackbar(`Display name updated to: ${displayName}`, { variant: "info" }))
}

export default takeLatest(UPDATE_DISPLAY_NAME_ACTION, handleUpdateDisplayName)
