import { takeLatest } from "redux-saga/effects"
import { AxiosResponse } from "axios"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { call, put } from "redux-saga/effects"
import drawRequestActions, {
  RespondToDrawRequestAction,
  RESPOND_TO_DRAW_REQUEST_ACTION,
} from "../actions/drawRequestActions"
import { UserEventStatus } from "@all-good-apps/draft-chess-common"

function* handleRespondToDrawRequest(action: RespondToDrawRequestAction) {
  const responseStatus: UserEventStatus = action.payload.status
  const requestId = action.payload.requestId

  const response: AxiosResponse = yield call(authHelpers.authenticatedRequest, {
    method: "POST",
    url: `draw/respond/${requestId}/${responseStatus}`,
  })

  const { status, data } = response

  if (status == 200) {
    yield put(drawRequestActions.updateDrawRequests([data]))
  }
}

export default takeLatest(RESPOND_TO_DRAW_REQUEST_ACTION, handleRespondToDrawRequest)
