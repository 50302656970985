import { ButtonBase } from "@mui/material";
import { Move } from "chess.js";
import { memo } from "react";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { gamesActions } from "@all-good-apps/draft-chess-client-api"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";
import { playFlipSound } from "../../sound/sagas/handlePlaySound";
import useSuggestionContext from "../hooks/useSuggestionContext";
import { highlightMove } from "../sagas/handleHighlightMove";
import { unhighlightSuggestion } from "../sagas/handleUnhighlightMove";
import FlippableSuggestionCard from "./FlippableSuggestionCard";
import { dismissSuggestion } from "../sagas/handleDismissSuggestion";

interface Props {
  suggestion?: Move;
  boardWidth: number;
}

const SuggestionButton = (props: Props) => {
  const { suggestion, boardWidth } = props;
  const { gameId } = gameHooks.useGameContext();
  const { highlightedMove, active, player } = useSuggestionContext();
  const dispatch = useDispatch();

  const highlighted =
    !!suggestion && !!highlightMove && suggestion?.san === highlightedMove?.san;

  const handleClick = (s?: Move) => {
    if (s && active) {
      // dispatch(playFlipSound())
      dispatch(gamesActions.move(gameId, s));
    }
  };

  const handleContextClick = () => {
    if (suggestion && active) {
      dispatch(dismissSuggestion(gameId, suggestion?.lan));
    }
  };

  const handleMouseOver = (s?: Move) => {
    if (active && player === "player" && !highlighted && s) {
      dispatch(highlightMove(gameId, s));
    }
  };

  const debouncedHandleMouseLeave = useDebouncedCallback(() => {
    if (active && player === "player" && suggestion) {
      dispatch(unhighlightSuggestion(gameId, suggestion));
    }
  }, 250);

  return (
    <ButtonBase
      onMouseOver={() => handleMouseOver(suggestion)}
      onMouseLeave={debouncedHandleMouseLeave}
      onContextMenu={(e) => {
        e.preventDefault();
        handleContextClick();
      }}
      sx={{
        "&:focus": {
          outlineWidth: 0,
        },
      }}
    >
      <FlippableSuggestionCard
        onClick={() => handleClick(suggestion)}
        boardWidth={boardWidth}
        highlighted={highlighted}
        suggestion={suggestion}
      />
    </ButtonBase>
  );
};

export default memo(SuggestionButton);
