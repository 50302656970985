import { useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import ChangeDisplayNameForm from "../forms/ChangeDisplayNameForm"
import DisplayNameLabel from "../../atoms/labels/DisplayNameLabel"
import DisplayNameValue from "../../atoms/values/DisplayNameValue"
import { makeStyles, Button, styled } from "@mui/material"

type Props = {
  displayName: string | undefined
}

const RootDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
}))

const DefaultDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  flex: 1,
}))

const DisplayNameInformation = (props: Props) => {
  const [expand, setExpand] = useState(false)
  const toggle = () => {
    setExpand((prev) => !prev)
  }

  return (
    <RootDiv>
      <DefaultDiv>
        <DisplayNameLabel />
      </DefaultDiv>
      {!expand ? (
        <DefaultDiv>
          <DisplayNameValue displayName={props.displayName} />
          <Button onClick={toggle} endIcon={<EditIcon />}>
            Edit
          </Button>
        </DefaultDiv>
      ) : (
        <DefaultDiv>
          <ChangeDisplayNameForm onClick={toggle} />
        </DefaultDiv>
      )}
    </RootDiv>
  )
}

export default DisplayNameInformation
