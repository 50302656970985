import { authActions } from "@all-good-apps/draft-chess-client-api"
import { EventChannel } from "@redux-saga/core"
import { eventChannel } from "redux-saga"
import { call, put, take } from "redux-saga/effects"
import { firebaseMessaging } from "../../../firebase/admin"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"
import history from "../../history/history"
import { getToken, onMessage } from "firebase/messaging"

const VAPID_KEY = "BN2Oh6jdG9dIrF6iNcp-FC5-40MqaeFF06qWYOvbdOmghzq975zSh5KJRV7v68uf61lHTNALKkRThXA-wqUhY0M"

const notifcationChannel = (): EventChannel<any> => {
  return eventChannel((emit) => {
    return firebaseMessaging
      ? onMessage(firebaseMessaging, (payload) => {
        emit(payload)
      })
      : () => null
  })
}

const getPermissionToken = async () => {
  const fcmToken = await getToken(firebaseMessaging, { vapidKey: VAPID_KEY })
  return fcmToken
}

const watchForNotifications = function* (): any {
  yield take(authActions.USER_LOGGED_IN)
  try {
    const token = yield call(getPermissionToken)
    yield call(authHelpers.authenticatedRequest, {
      method: "POST",
      url: `/notifications/register`,
      params: {
        fcmToken: token,
      },
    })
  } catch (e) {
    console.log("Get token failed: ", e)
  }

  const channel = yield notifcationChannel()
  while (true) {
    const payload = yield take(channel)
    console.log("got notifications", payload)
    const notification = payload.data
    const { type, gameId, fromDisplayName } = notification
    switch (type) {
      case "move":
        if (!history.location.pathname.includes(`/games/${gameId}`)) {
          yield put(
            enqueueSnackbar(`${fromDisplayName} just played ${notification.move}.`, {
              variant: "info",
            })
          )
        }
    }
  }
}

export default watchForNotifications
