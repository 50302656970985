import { Paper, Stack } from "@mui/material"
import { useSelector } from "react-redux"
import SignInButton from "../../../auth/components/SignInButton"
import { authSelectors } from "@all-good-apps/draft-chess-client-api"
import profileSelector from "../../selectors/profileSelector"
import YourInfoTitle from "../atoms/titles/YourInfoTitle"
import DisplayNameInformation from "../molecules/information/DisplayNameInformation"
import EloRatingInformation from "../molecules/information/EloRatingInformation"
import EmailInformation from "../molecules/information/EmailInformation"

type Props = {}

const YourInfoSection = (props: Props) => {
  const userPublic = useSelector(profileSelector.userInfoPublic)
  const userPrivate = useSelector(profileSelector.userInfoPrivate)
  const isAnonymous = useSelector(authSelectors.isAnonymous)

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Stack spacing={2} padding={1}>
        <YourInfoTitle />
        <DisplayNameInformation displayName={userPublic.displayName} />
        <EloRatingInformation rating={userPublic.rating} />
        {!isAnonymous ? <EmailInformation email={userPrivate.email} /> : null}
        {isAnonymous ? <SignInButton fullWidth /> : null}
        <SignInButton mode="signIn" variant="outlined" />
      </Stack>
    </Paper>
  )
}

export default YourInfoSection
