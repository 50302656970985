import { updateUserRequestUI } from "../reducers/userRequestsUIReducer"
import { call, put, takeLatest } from "redux-saga/effects"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { SendRematchRequestAction, SEND_REMATCH_ACTION } from "../actions/rematchRequestActions"

function* handleSendRematchRequest(action: SendRematchRequestAction) {
  const to = action.payload.uid as string
  const gameId = action.payload.gameId

  yield put(updateUserRequestUI({ rematchLoading: true }))
  yield call(authHelpers.authenticatedRequest, {
    method: "POST",
    url: `rematch/issue/${to}/${gameId}`,
  })
  yield put(updateUserRequestUI({ rematchLoading: false }))
}

export default takeLatest(SEND_REMATCH_ACTION, handleSendRematchRequest)
