import { Move } from "chess.js"
import { useEffect, useState } from "react"
import Flippy, { BackSide, FrontSide } from "react-flippy"
import { useDispatch } from "react-redux"
import { playFlipSound } from "../../sound/sagas/handlePlaySound"
import useSuggestionContext from "../hooks/useSuggestionContext"
import SuggestionCardBack from "./SuggestionCardBack"
import SuggestionCardFace from "./SuggestionCardFace"

type Props = {
  onClick: Function
  boardWidth: number
  suggestion?: Move
  highlighted: boolean
  mobile?: boolean
}

/**
 * card face up = show suggestions is true
 * card face down = local
 * flipped===false is front, flipped===true is back
 */
const FlippableSuggestionCard = ({ onClick, boardWidth, suggestion, highlighted, mobile }: Props) => {
  console.log("FlippableSuggestionCard", suggestion)

  const [savedSuggestion, setSavedSuggestion] = useState(suggestion)
  const [faceDown, setFaceDown] = useState(true)
  const { player, active } = useSuggestionContext()

  const handleClick = () => {
    if (player !== "player") {
      return
    }
    onClick()
  }

  useEffect(() => {
    setFaceDown(true)
    const timeout = setTimeout(() => {
      setSavedSuggestion(suggestion)
      setFaceDown(!suggestion)
      // dispatch(playFlipSound())
    }, 500)

    return () => clearTimeout(timeout)
  }, [suggestion?.lan, active])

  return (
    <Flippy
      onClick={handleClick}
      flipOnClick={false}
      isFlipped={faceDown && !highlighted}
      style={{
        width: (boardWidth / 4.25) * 0.75,
        height: boardWidth / 4.5,
      }}
    >
      <FrontSide style={{ padding: 0, margin: 0 }} duration={1000}>
        {savedSuggestion && <SuggestionCardFace suggestion={savedSuggestion} highlighted={highlighted} />}
      </FrontSide>
      <BackSide style={{ padding: 0, margin: 0 }}>
        <SuggestionCardBack />
      </BackSide>
    </Flippy>
  )
}

export default FlippableSuggestionCard
