import { Move } from "chess.js"
import { Reducer } from "redux"
import { RootState } from "../../../store"

export interface SuggestionsState {
  showSuggestions: boolean
  showHighlights: boolean
  highlightedMove?: Move
  suggestions: Move[]
}

export interface SuggestionsByGameIdState {
  [gameId: string]: SuggestionsState
}

const initialState: SuggestionsByGameIdState = {}

export const UPDATE_SUGGESTIONS_STATE_ACTION = "games/UPDATE_SUGGESTIONS"
export const updateSuggestionsState = (gameId: string, suggestionState: Partial<SuggestionsState>) => ({
  type: UPDATE_SUGGESTIONS_STATE_ACTION,
  payload: {
    gameId,
    suggestionState,
  },
})
export type UpdateSuggestionsStateAction = ReturnType<typeof updateSuggestionsState>

const suggestionsByGameIdReducer: Reducer<SuggestionsByGameIdState, UpdateSuggestionsStateAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UPDATE_SUGGESTIONS_STATE_ACTION:
      const { gameId, suggestionState } = action.payload
      const currentSuggestions = {
        ...state[gameId],
        ...suggestionState,
      }

      return {
        ...state,
        [gameId]: currentSuggestions,
      }
    default:
      return state
  }
}

// Selectors
export const selectSuggestionsState = (state: RootState, gameId: string) =>
  state.suggestions.suggestionsByGameId[gameId]

export const selectHighlightedMove = (state: RootState, gameId: string) =>
  selectSuggestionsState(state, gameId)?.highlightedMove
export const selectShowSuggestions = (state: RootState, gameId: string) =>
  selectSuggestionsState(state, gameId)?.showSuggestions

export default suggestionsByGameIdReducer
