import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import GroupIcon from "@mui/icons-material/Group"
import Logout from "@mui/icons-material/Logout"
import { styled, Typography } from "@mui/material"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Tooltip from "@mui/material/Tooltip"
import * as React from "react"
import { useDispatch } from "react-redux"
import { authActions } from "@all-good-apps/draft-chess-client-api"
import theme from "../../common/theme"
import FriendsList from "./FriendsList"

const RootDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.5),
}))

const DividerDiv = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.text.primary,
}))

const FriendListDiv = styled("div")(({ theme }) => ({
  color: theme.palette.text.primary,
}))

const MenuItemsDiv = styled("div")(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(-2),
  paddingLeft: theme.spacing(0.5),
}))

const TitleDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  color: theme.palette.text.primary,
}))

const FriendListMenu = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    dispatch(authActions.logOut())
  }

  return (
    <>
      <Tooltip title="Account">
        <IconButton onClick={handleClick} size="small" color="primary">
          <GroupIcon
            sx={{
              width: 28,
              height: 28,
              color: (theme) => theme.palette.primary.main,
              "& root": {
                outline: "none",
              },
              "& focus": {
                outline: 0,
              },
            }}
          ></GroupIcon>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        <RootDiv>
          <TitleDiv>
            <Typography component="h1" variant="h6">
              Friends
            </Typography>
            <AccountCircleIcon />
          </TitleDiv>
          <FriendListDiv>
            <FriendsList />
          </FriendListDiv>
          <Divider sx={{ marginTop: 1, backgroundColor: (theme) => theme.palette.text.primary }} />
          <MenuItemsDiv>
            <MenuItem onClick={handleLogOut}>
              <Logout />
              <Typography>Logout</Typography>
            </MenuItem>
          </MenuItemsDiv>
        </RootDiv>
      </Menu>
    </>
  )
}

export default FriendListMenu
