import { LoadingButton } from "@mui/lab"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";
import { selectInPool, selectAddingToMatchmakingPool } from "../reducers/matchmakingUIReducer"
import { addToMatchmakingPool } from "../sagas/handleAddToMatchMakingPool"
import { removeFromMatchmakingPool } from "../sagas/handleRemoveFromMatchmakingPool"

const NewOpponentButton = () => {
  const { gameConfig } = gameHooks.useGameContext()
  const inPool = useSelector(selectInPool)
  const addingToPool = useSelector(selectAddingToMatchmakingPool)

  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(removeFromMatchmakingPool(gameConfig))
    }
  }, [dispatch])

  const handleNewOpponent = () => {
    dispatch(!inPool ? addToMatchmakingPool(gameConfig) : removeFromMatchmakingPool(gameConfig))
  }

  return (
    <LoadingButton
      variant={inPool ? "outlined" : "contained"}
      loading={addingToPool}
      onClick={handleNewOpponent}
      color="primary"
      size="small"
    >
      {!inPool ? "New Opponent" : "Searching..."}
    </LoadingButton>
  )
}

export default NewOpponentButton
