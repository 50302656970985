import React from "react"
import Piece from "react-chess-pieces"
import { styled } from "@mui/material"

type Props = { boardWidth: number }

const SuggestionCardContainer = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  backgroundColor: theme.palette.primary.light,

  borderRadius: "11%",
  borderWidth: 0,
  borderColor: theme.palette.background.default,
  borderStyle: "solid",
  "&:focus": {
    outlineWidth: 0,
  },
  ".MuiTouchRipple": {
    backgroundColor: "red",
  },

  // background: `repeating-linear-gradient(45deg,  #fff 5px,  ${theme.palette.primary.light} 1px, ${theme.palette.primary.dark} 10px)`,
  // outlineColor: theme.palette.primary.dark,
  // outlineWidth: -2,
  // outlineStyle: "solid",
}))

const SuggestionCardBack = () => {
  return (
    <SuggestionCardContainer>
      <img
        src="/KingIcon.png"
        style={{
          height: "50%",
        }}
      />
      {/* <div className="cardbackpiece">
        <Piece piece={"k"} />
      </div> */}
    </SuggestionCardContainer>
  )
}

export default SuggestionCardBack
