import { takeLatest } from "@redux-saga/core/effects"
import { call, put } from "redux-saga/effects"
import { authHelpers } from "@all-good-apps/draft-chess-client-api"
import { enqueueSnackbar } from "../../snackbar/snackbarReducer"
import { SendChallengeAction, SEND_CHALLENGE_ACTION } from "../actions/challengeActions"
import { updateUserRequestUI } from "../reducers/userRequestsUIReducer"

function* handleSendChallenge(action: SendChallengeAction) {
  const to = action.payload.uid as string

  yield put(updateUserRequestUI({ challengeLoading: true }))
  try {
    yield call(authHelpers.authenticatedRequest, {
      method: "POST",
      url: `challenge/issue`,
      data: {
        toPlayer: to,
        gameConfig: action.payload.gameConfig,
      },
    })
  } catch (e) {
    yield put(enqueueSnackbar("There was a problem sending challenge request.", { variant: "error" }))
    return
  } finally {
    yield put(updateUserRequestUI({ challengeLoading: false }))
  }

  yield put(enqueueSnackbar("Challenge sent.", { variant: "success" }))
}

export default takeLatest(SEND_CHALLENGE_ACTION, handleSendChallenge)
