import { RootState } from "../../../store"

export interface UserRequestsUIState {
  challengeLoading: boolean
  respondToChallengeLoading: boolean
  rematchLoading: boolean
  responedToRematchLoading: boolean
}

const initialState: UserRequestsUIState = {
  challengeLoading: false,
  respondToChallengeLoading: false,
  rematchLoading: false,
  responedToRematchLoading: false,
}

export const UPDATE_USER_REQUEST_UI_ACTION = `userRequest/UPDATE_USER_REQUEST_UI_ACTION`

export const updateUserRequestUI = (payload: Partial<UserRequestsUIState>) => ({
  type: UPDATE_USER_REQUEST_UI_ACTION,
  payload,
})

type UpdateUserRequestUIAction = ReturnType<typeof updateUserRequestUI>

const userRequestsUIReducer = (state = initialState, action: UpdateUserRequestUIAction) => {
  switch (action.type) {
    case UPDATE_USER_REQUEST_UI_ACTION:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const selectUserRequestsUI = (state: RootState) => state.userRequests.userRequestsUI
export const selectChallengeLoading = (state: RootState) => selectUserRequestsUI(state).respondToChallengeLoading

export default userRequestsUIReducer
