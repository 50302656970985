import { Invite } from "@all-good-apps/draft-chess-common"
import { RootState } from "../../../store"
import { fetchInvite } from "../sagas/handleFetchInvite"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectInvite } from "../reducers/invitesReducer"

export const useInvite = (inviteId: string): Invite => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchInvite(inviteId))
  }, [inviteId])

  const invite = useSelector((state: RootState) => selectInvite(state, inviteId))

  return (
    invite || {
      inviteId: "",
      gameConfig: {
        variant: "3check",
        timeControl: { readableName: "30s" },
      },
    }
  )
}
