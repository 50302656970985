import { REMOVED } from "../../common/sagas/realtimeDatabaseDocChangesChannel"
import { Move } from "chess.js"
import { updateSuggestionsState } from "../reducers/suggestionsByGameIdReducer"
// actions

import { cancel, fork, put, take, takeEvery } from "redux-saga/effects"
import realtimeDatabaseDocChangesChannel from "../../common/sagas/realtimeDatabaseDocChangesChannel"
import suggestionRefs from "./suggestionRefs"

export const START_LISTEN_FOR_HIGHLIGHTED_MOVE = `suggestions/START_LISTEN_FOR_HIGHLIGHTED_MOVE`
export const startListenForHighlightedMove = (gameId: string) => ({
  type: START_LISTEN_FOR_HIGHLIGHTED_MOVE,
  payload: {
    gameId,
  },
})
export type StartListeningForHighlightedMoveAction = ReturnType<typeof startListenForHighlightedMove>

export const STOP_LISTEN_FOR_HIGHLIGHTED_MOVE = `suggestions/STOP_LISTEN_FOR_HIGHLIGHTED_MOVE`
export const stopListenForHighlightedMove = (gameId: string) => ({
  type: STOP_LISTEN_FOR_HIGHLIGHTED_MOVE,
  payload: {
    gameId,
  },
})
export type StopListeningForHighlightedMoveAction = ReturnType<typeof stopListenForHighlightedMove>

function* listenForHighlightedMoveSaga(gameId: string): any {
  const channel = realtimeDatabaseDocChangesChannel(suggestionRefs.highlightedMove(gameId))

  while (true) {
    const move: Move | typeof REMOVED = yield take(channel)
    if (move === REMOVED) {
      yield put(
        updateSuggestionsState(gameId, {
          highlightedMove: undefined,
        })
      )
    } else {
      yield put(
        updateSuggestionsState(gameId, {
          highlightedMove: move,
        })
      )
    }
  }
}

function* listenForHighlightedMove(action: StartListeningForHighlightedMoveAction): any {
  const { gameId } = action.payload
  const task = yield fork(listenForHighlightedMoveSaga, gameId)

  while (true) {
    const stopAction: StopListeningForHighlightedMoveAction = yield take(STOP_LISTEN_FOR_HIGHLIGHTED_MOVE)
    if (stopAction.payload.gameId === gameId) {
      yield cancel(task)
      return
    }
  }
}

export default takeEvery(START_LISTEN_FOR_HIGHLIGHTED_MOVE, listenForHighlightedMove)
