import { Invite } from "@all-good-apps/draft-chess-common"
import { RootState } from "../../../store"

interface InviteState {
  [inviteId: string]: Invite
}

const initialState: InviteState = {}

export const UPDATE_INVITE = `invites/UPDATE_INVITE`

export const updateInviteState = (invite: Invite) => ({
  type: UPDATE_INVITE,
  payload: {
    invite,
  },
})

export type UpdateInviteStateAction = ReturnType<typeof updateInviteState>

const invitesReducer = (state = initialState, action: UpdateInviteStateAction) => {
  switch (action.type) {
    case UPDATE_INVITE:
      return {
        ...state,
        [action.payload.invite.inviteId]: action.payload.invite,
      }
    default:
      return state
  }
}

export const selectInvite = (state: RootState, inviteId: string) => state.invites.invites[inviteId]

export default invitesReducer
