import { useSelector } from "react-redux"
import { RootState } from "../../../store"
import FriendListItem from "./FriendListItem"
import profileSelector from "../selectors/profileSelector"
import { PublicProfile } from "@all-good-apps/draft-chess-common"
import { Typography } from "@mui/material"

const FriendsList = () => {
  const publicProfileInfo: PublicProfile | undefined = useSelector((state: RootState) => profileSelector.userInfoPublic(state))

  if (!publicProfileInfo) {
    return null
  }

  const friends = publicProfileInfo?.friends || []
  if (friends.length === 0) {
    return (
      <Typography variant="subtitle2">
        You currently have no friends, add a friend on the profile page to play Draft Chess against them!
      </Typography>
    )
  }

  return (
    <>
      {friends.map((friend) => (
        <FriendListItem key={`friend.${friend}`} friendUid={friend} />
      ))}
    </>
  )
}

export default FriendsList
