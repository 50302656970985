import NotificationsIcon from "@mui/icons-material/Notifications"
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone"
import { styled, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import Tooltip from "@mui/material/Tooltip"
import * as React from "react"
import FriendRequestList from "../../userRequests/components/FriendRequestList"

const MenuItemsDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
}))

const TitleAreaDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  color: theme.palette.text.primary,
}))

const NotificationMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton onClick={handleClick} size="small">
          <NotificationsNoneTwoToneIcon
            sx={{ width: 28, height: 28, color: (theme) => theme.palette.primary.main, padding: 0, margin: 0 }}
          ></NotificationsNoneTwoToneIcon>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="notification-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: (theme) => theme.palette.background.paper,
          },
        }}
      >
        <TitleAreaDiv>
          <Typography component="h1" variant="h6" sx={{ paddingLeft: (theme) => theme.spacing(1) }}>
            Notifications
          </Typography>
          <NotificationsIcon />
        </TitleAreaDiv>
        <MenuItemsDiv>
          <FriendRequestList />
        </MenuItemsDiv>
      </Menu>
    </>
  )
}

export default NotificationMenu
