import { off, onValue, ref } from "firebase/database"
import { EventChannel, eventChannel } from "redux-saga"
import { put, race, select, take } from "redux-saga/effects"
import { PublicProfile } from "@all-good-apps/draft-chess-common"
import { database } from "../../../firebase/admin"
import { authSelectors, authActions } from "@all-good-apps/draft-chess-client-api"
import { fetchPublicProfiles } from "./handleFetchPublicProfile"

const otherUsersChannel = (uid: string): EventChannel<PublicProfile> => {
  return eventChannel<PublicProfile>((emit) => {
    const publicProfileRef = ref(database, "publicProfiles/" + uid)

    onValue(publicProfileRef, (snapshot) => {
      const publicProfileData = snapshot.val()
      if (publicProfileData) {
        emit(publicProfileData as PublicProfile)
      }
    })
    return () => {
      off(publicProfileRef, "value")
    }
  })
}

function* listenForOtherPublicProfiles(uid: string) {
  const channel: EventChannel<PublicProfile> = yield otherUsersChannel(uid)
  while (true) {
    const updatedUser: PublicProfile = yield take(channel)
    const friends = updatedUser.friends
    yield put(fetchPublicProfiles(friends))
  }
}

function* listenForOtherUsers() {
  while (true) {
    yield take(authActions.USER_LOGGED_IN)
    const uid: string = yield select(authSelectors.uid)
    yield race([listenForOtherPublicProfiles(uid), take(authActions.USER_LOGGED_OUT)])
  }
}

export default listenForOtherUsers
