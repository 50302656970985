import { ReplayRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import { Button, Card, Grid, IconButton, Paper, Stack, Typography } from "@mui/material"
import Piece from "react-chess-pieces"
import { useDispatch } from "react-redux"
import { playSound } from "../../sound/sagas/handlePlaySound"

const typoColor: any[] = ["primary", "secondary", "textPrimary", "textSecondary", "error"]
const typoVariants: any[] = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "subtitle1",
  "subtitle2",
  "body1",
  "body2",
  "caption",
  "button",
  "overline",
]

const testSuggestions = [
  {
    color: "w",
    flags: "n",
    from: "c8",
    piece: "b",
    san: "Bd7",
    to: "d7",
  },
  {
    color: "b",
    flags: "n",
    from: "d8",
    piece: "q",
    san: "Qd7",
    to: "d7",
  },
  {
    color: "b",
    flags: "n",
    from: "d8",
    piece: "q",
    san: "Qd6",
    to: "d6",
  },
]

const colors: any[] = ["default", "primary", "secondary"]
const sizes: any[] = ["small", "medium", "large"]
const buttonVariants: any[] = ["contained", "outlined", "text"]
const textFieldVariants: any[] = ["filled", "outlined", "standard"]

const ThemeTest = () => {
  const dispatch = useDispatch()
  return (
    <Grid
      container
      //   direction="row"
      style={{
        margin: 20,
        // width: "100vw",
        // display: "flex",
        // flexDirection: "row",
        // backgroundColor: "lightblue",
      }}
      spacing={1}
    >
      <Grid direction="column" component={Paper}>
        {typoColor.map((color) => (
          <Grid item>
            <Typography variant="h1" color={color}>
              {color}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid direction="column" component={Paper} style={{ marginLeft: 10, padding: 20 }}>
        <Stack spacing={1}>
          <Typography>text</Typography>
          <Button variant="text" onClick={() => dispatch(playSound("dog"))}>
            Play sound
          </Button>
          <Button variant="text" disabled>
            Button
          </Button>
          <LoadingButton variant="text" loading>
            Button
          </LoadingButton>
        </Stack>
        <Stack spacing={1}>
          <Typography>outlined</Typography>
          <Button variant="outlined">Button</Button>
          <Button variant="outlined" disabled>
            Button
          </Button>
          <LoadingButton variant="outlined" loading>
            Button
          </LoadingButton>
        </Stack>
        <Stack spacing={1}>
          <Typography>contained</Typography>
          <Button variant="contained">Button</Button>
          <Button variant="contained" disabled>
            Button
          </Button>
          <LoadingButton variant="contained" loading>
            Button
          </LoadingButton>
        </Stack>
      </Grid>
      <Grid component={Paper}>
        {typoVariants.map((v) => (
          <Grid item>
            <Typography variant={v}>{v}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Card />
      </Grid>
      <Grid item>
        <Paper />
      </Grid>
      <Grid>
        <Paper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {testSuggestions.map((suggestion) => {
              return (
                <Button disableElevation={false} variant="contained">
                  <div>
                    <div className="piece">
                      <Piece piece={suggestion.color === "b" ? suggestion.piece : suggestion.piece.toUpperCase()} />
                    </div>
                    <Typography
                      variant="button"
                      color="secondary"
                      style={{
                        fontSize: 18,
                        fontWeight: "bolder",
                      }}
                    >
                      {suggestion.san}
                    </Typography>
                  </div>
                </Button>
              )
            })}
            <IconButton>
              <ReplayRounded fontSize="large" />
            </IconButton>
          </div>
        </Paper>
      </Grid>
      <Grid container>
        <Stack spacing={4} direction="row">
          {/* <SuggestionButton
            boardWidth={500}
            suggestion={{ color: "w", flags: "n", from: "b1", piece: "n", san: "Nc3", to: "c3", lan: "b1c3" }}
          /> */}
        </Stack>
      </Grid>
    </Grid>
  )
}

export default ThemeTest
