import { OpenInNew } from "@mui/icons-material"
import { IconButton, styled } from "@mui/material"
import { useDispatch } from "react-redux"
import { navigate } from "../../history/historyReducer"
import { gameHooks } from "@all-good-apps/draft-chess-client-api";

interface Props {
  gameId: string
}

const RootDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}))

const GameMenu = () => {
  const { gameId } = gameHooks.useGameContext()
  const dispatch = useDispatch()
  const handleNav = () => {
    dispatch(navigate(`/games/${gameId}`))
  }

  return (
    <RootDiv>
      {/* <FlagMenuAndButton /> */}
      <IconButton color="primary" size="small" onClick={handleNav}>
        <OpenInNew />
      </IconButton>
    </RootDiv>
  )
}

export default GameMenu
