import { List, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import friendRequestSelector from "../selectors/friendRequestSelector"
import FriendRequestItem from "./FriendRequestItem"

const FriendRequestList = () => {
  const friendRequestIds = useSelector(friendRequestSelector.friendRequestIds)

  if (friendRequestIds.length === 0) {
    return null
  }

  return (
    <div>
      <Typography variant="h6">Friend Requests</Typography>
      <List>
        {friendRequestIds.map((friendRequestId) => (
          <FriendRequestItem key={`friendRequest.${friendRequestId}`} friendRequestId={friendRequestId} />
        ))}
      </List>
    </div>
  )
}

export default FriendRequestList
