import { RootState } from "../../../store"

export interface InviteUIState {
  acceptingInvite: boolean
  deletingInvite: boolean
  creatingInvite: boolean
  fetchingInvite: boolean

  fetchInviteError?: string
}

const initialState: InviteUIState = {
  fetchingInvite: false,
  acceptingInvite: false,
  deletingInvite: false,
  creatingInvite: false,
}

export const UPDATE_INVITE_UI_STATE = `invite/UPDATE_INVITE_UI_STATE`
export const updateInviteUIState = (payload: Partial<InviteUIState>) => ({
  type: UPDATE_INVITE_UI_STATE,
  payload,
})
export type UpdateInviteUIStateAction = ReturnType<typeof updateInviteUIState>

const inviteUiReducer = (state = initialState, action: UpdateInviteUIStateAction) => {
  switch (action.type) {
    case UPDATE_INVITE_UI_STATE:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const selectAcceptingInvite = (state: RootState) => state.invites.ui.acceptingInvite
export const selectDeletingInvite = (state: RootState) => state.invites.ui.deletingInvite
export const selectCreatingInvite = (state: RootState) => state.invites.ui.creatingInvite

export default inviteUiReducer
