import { Router } from "react-router-dom"
import history from "../../history/history"
import LoadingScreen from "../../auth/components/LoadingScreen"
import BaseRoutes from "./Routes"
import { useSelector } from "react-redux"
import { selectLocation } from "../../history/historyReducer"

const AuthGate = () => {
  // This is a hack to force the page to re-render when the location changes
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useSelector(selectLocation)
  return (
    <>
      <LoadingScreen />
      <Router location={history.location} navigator={history}>
        <BaseRoutes />
      </Router>
    </>
  )
}

export default AuthGate
