import { UserEventStatus, UserRequest } from "@all-good-apps/draft-chess-common"
import { FriendRequestState } from "../types/friendRequestTypes"

export const UPDATE_FRIEND_REQUEST_STATE_ACTION = "friendRequest/UPDATE_FRIEND_REQUEST_STATE_ACTION"
export const UPDATE_FRIEND_REQUESTS_ACTION = "friendRequest/UPDATE_FRIEND_REQUESTS_ACTION"
export const SEND_FRIEND_REQUEST_ACTION = "friendRequest/SEND_FRIEND_REQUEST_ACTION"
export const RESPOND_TO_FRIEND_REQUEST_ACTION = "friendRequest/RESPOND_TO_FRIEND_REQUEST_ACTION"

const updateRequests = (requests: UserRequest[]) => ({
  type: UPDATE_FRIEND_REQUESTS_ACTION,
  payload: { requests },
})

const updateFriendRequestState = (payload: Partial<FriendRequestState>) => ({
  type: UPDATE_FRIEND_REQUEST_STATE_ACTION,
  payload,
})

const sendFriendRequest = (email: string) => ({
  type: SEND_FRIEND_REQUEST_ACTION,
  payload: {
    email,
  },
})

const respondToFriendRequest = (requestId: string, status: UserEventStatus) => ({
  type: RESPOND_TO_FRIEND_REQUEST_ACTION,
  payload: {
    requestId: requestId,
    status: status,
  },
})

export type UpdateFriendRequestStateAction = ReturnType<typeof updateFriendRequestState>
export type UpdateFriendRequestsAction = ReturnType<typeof updateRequests>
export type SendFriendRequestAction = ReturnType<typeof sendFriendRequest>
export type RespondToFriendRequestAction = ReturnType<typeof respondToFriendRequest>

export type FriendsActions = UpdateFriendRequestStateAction | SendFriendRequestAction | RespondToFriendRequestAction

export default {
  updateRequests,
  updateFriendRequestState,
  sendFriendRequest,
  respondToFriendRequest,
}
