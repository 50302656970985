import { PublicProfile } from "@all-good-apps/draft-chess-common"
import { off, onValue, ref } from "firebase/database"
import { EventChannel, eventChannel } from "redux-saga"
import { put, race, select, take } from "redux-saga/effects"
import { database } from "../../../firebase/admin"
import { authActions, authSelectors } from "@all-good-apps/draft-chess-client-api"
import profileActions from "../actions/profileActions"

const publicProfileChannel = (uid: string): EventChannel<PublicProfile> => {
  return eventChannel<PublicProfile>((emit) => {
    const publicProfileRef = ref(database, "publicProfiles/" + uid)
    onValue(publicProfileRef, (snapshot) => {
      const publicProfileData = snapshot.val()
      if (publicProfileData) {
        emit(publicProfileData as PublicProfile)
      }
    })
    return () => {
      off(publicProfileRef, "value")
    }
  })
}

function* listenForChanges(uid: string): any {
  const channel: EventChannel<PublicProfile> = yield publicProfileChannel(uid)
  while (true) {
    const updatedProfile: PublicProfile = yield take(channel)
    yield put(profileActions.updatePublicUserInfo(updatedProfile))
  }
}

function* listenForPublicProfile() {
  while (true) {
    yield take(authActions.USER_LOGGED_IN)
    const uid: string = yield select(authSelectors.uid)
    yield race([listenForChanges(uid), take(authActions.USER_LOGGED_OUT)])
  }
}

export default listenForPublicProfile
